import React, { useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import GilroyRegular from "../../assets/Gilroy-Regular.ttf";
import background from "../../assets/backgroundControls.jpg";
import logo from "../../assets/whiteLogo.png";
import { Form } from "@unform/web";
import Input from "../Form/input";
import api from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardHeader,
  TextField,
  InputAdornment,
  SvgIcon,
  Button,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import ModalConfirm from "../components/ModalConfirm";
import ModalDeny from "../components/ModalDeny";
import ModalImage from "../components/ModalImage";
import { ArrowBack } from "@material-ui/icons";

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100vw",
    backgroundRepeat: "repeat",
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  div: {
    maxWidth: "100vw",
    backgroundColor: "#e8e8e8",
    borderRadius: 30,
    overflowX: "auto",
  },
  content: {
    zIndex: 5,
    display: "flex",
    paddingTop: 30,
    paddingBottom: 30,
    flexDirection: "column",
  },
  input: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    borderRadius: 25,
    border: "none",
    color: "#383838",
    backgroundColor: "#e8e8e8",
    padding: 16,
    width: "100%",
    marginTop: "1%",
    marginBottom: "4%",
    position: "relative",
  },
  btn: {
    color: "#fff",
    borderWidth: 0,
    borderRadius: 40,
    fontSize: 16,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    backgroundImage: "linear-gradient(180deg,#e6007e 0%,#e94834 100%)",
    padding: "0.8em 1em",
    lineHeight: "1.7em",
    width: "100%",
    fontWeight: 600,
    margin: "5% 0",
  },
  "@font-face": {
    fontFamily: "Gilroy-regular",
    src: `url(${GilroyRegular})`,
  },
  logo: {
    width: "30%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  brand: {
    width: "15%",
    padding: "3%",
  },
  titleBrand: {
    padding: "1% 0",
    display: "flex",
    alignItems: "center",
    marginLeft: "-3%",
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    padding: "2% 1%",
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
    width: "100%",
  },
  label: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 16,
    paddingLeft: "2%",
  },
  forgotDiv: {
    width: "100%",
    textAlign: "right",
  },
  forgot: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    textDecoration: "none",
  },
  errorDiv: {
    width: "100%",
    textAlign: "right",
    margin: 0,
    position: "absolute",
  },
  error: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#D84141",
    fontSize: 14,
    textDecoration: "none",
    margin: 0,
    fontWeight: 600,
  },
  singIn: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
  },
  singInLink: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: 600,
  },
  iconPassword: {
    color: "#383838",
    right: 21,
    top: 8,
    height: 44,
    fontSize: 20,
    position: "absolute",
  },
  quit: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#e6007e",
    width: "fit-content",
    textAlign: "left",
    textDecoration: "none",
    cursor: "pointer",
    paddingLeft: 16,
    paddingRight: 16,
  },
  createLink: {
    padding: "2% 0",
    textDecoration: "none",
    display: "flex",
    justifyContent: "flex-end",
    width: "50%",
  },
  icon: {
    fontSize: 30,
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
});

function Payments(props) {
  const { classes } = props;
  const formRef = useRef(null);
  const [deleted, setDeleted] = React.useState(0);
  const [payments, setPayments] = React.useState([]);
  const [fixedPayments, setFixedPayments] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);

  useEffect(() => {
    async function setPaymentsValues() {
      var response = await api.put(`/wallet/listTransactions`, {
        paymentStatusCode: "waiting",
        paymentTypeCode: "credit",
      });
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].wallet.user.cpf = mCPF(
          response.data[i].wallet.user.cpf
        );
        response.data[i].created_at = new Date(
          response.data[i].created_at
        ).toLocaleDateString("pt-br");

        response.data[i].payment_value = response.data[
          i
        ].payment_value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      }
      setPayments(response.data);
      setFixedPayments(response.data);
      setDisabled(false);
    }
    setPaymentsValues();
  }, [deleted]);

  async function responsePayment(data) {
    setDisabled(true);
    var id = data.id;
    delete data.id;
    try {
      await api.put(`/wallet/updateTransaction/` + id, data);
    } catch (e) {
      setDisabled(false);
    }
    setDeleted(deleted + 1);
  }

  function changeSearch(e) {
    if (e.key === "Enter") {
      let keyword = e.target.value;
      let filtered = fixedPayments.filter(
        (payment) =>
          payment.bank.title_bank
            .toUpperCase()
            .includes(keyword.toUpperCase()) ||
          payment.payment_value.toUpperCase().includes(keyword.toUpperCase()) ||
          payment.wallet.user.full_name
            .toUpperCase()
            .includes(keyword.toUpperCase()) ||
          payment.wallet.user.email
            .toUpperCase()
            .includes(keyword.toUpperCase()) ||
          payment.wallet.user.cpf
            .toUpperCase()
            .includes(keyword.toUpperCase()) ||
          payment.created_at.toUpperCase().includes(keyword.toUpperCase())
      );
      setPayments(filtered);
    }
  }
  function mCPF(cpf) {
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  }

  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <ResponsiveAppBar></ResponsiveAppBar>
      <div className={classes.div}>
        <div className={classes.content}>
          <Link className={classes.quit} to="/payments">
            <ArrowBack fontSize="large" />
          </Link>
          <div>
            <Table style={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className={classes.title}>Depósitos pendentes</div>{" "}
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      onKeyUp={changeSearch}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <FontAwesomeIcon icon={faSearch} />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Buscar Pagamento"
                      variant="outlined"
                      style={{ backgroundColor: "#fff", minWidth: 200 }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Table style={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Banco</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>Telefone</TableCell>
                  <TableCell>Cpf</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Comprovante</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((payment) => (
                  <TableRow
                    hover
                    key={payment.id}
                    style={{ backgroundColor: "#fff" }}
                  >
                    <TableCell>
                      <img
                        src={payment.bank.logo_img}
                        alt={payment.bank.title_bank}
                        style={{ width: 40, userSelect: "none" }}
                      />
                    </TableCell>
                    <TableCell>{payment.id}</TableCell>
                    <TableCell>{payment.payment_value}</TableCell>
                    <TableCell>{payment.bank.title_bank}</TableCell>
                    <TableCell>
                      {" "}
                      {payment.wallet.user.full_name}
                      <br />
                      <a
                        target="_blank"
                        href={`/userFinancials/${payment.wallet.user.id}`}
                        rel="noreferrer"
                      >
                        Extrato
                      </a>
                      {!payment.wallet.user.active ? (
                        <>
                          <br />
                          <span style={{ color: "red" }}>BLOQUEADO</span>
                        </>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell> {payment.wallet.user.email}</TableCell>
                    <TableCell> {payment.wallet.user.phone}</TableCell>
                    <TableCell> {payment.wallet.user.cpf}</TableCell>
                    <TableCell> {payment.created_at}</TableCell>
                    <TableCell>
                      <ModalImage
                        className={classes.icon}
                        img={payment.receipt_url}
                      />
                    </TableCell>
                    <TableCell>
                      <ModalConfirm
                        style={{
                          backgroundColor: "#17882c",
                          color: "#fff",
                          padding: 5,
                          width: "45%",
                          margin: "0 2.5px",
                        }}
                      >
                        <CardHeader
                          subheader="Digite o valor do comprovante para confirmar o pagamento."
                          title="Confirmar pagamento"
                        />
                        <Form
                          style={{ position: "relative" }}
                          ref={formRef}
                          onSubmit={responsePayment}
                        >
                          <Input
                            required
                            name="payment_value"
                            type="number"
                            min="0.00"
                            step="0.01"
                            placeholder="Valor do comprovante"
                            className={classes.input}
                          />
                          <Input
                            required
                            name="id"
                            type="hidden"
                            value={payment.id}
                          />
                          <Input
                            required
                            name="payment_status"
                            type="hidden"
                            value="paid"
                          />

                          <Button
                            variant="contained"
                            style={{
                              margin: 10,
                              backgroundColor: "#17882c",
                              color: "#fff",
                            }}
                            className={disabled ? classes.disabled : ""}
                            disabled={disabled}
                            type="submit"
                          >
                            Confirmar
                          </Button>
                        </Form>
                      </ModalConfirm>
                      <ModalDeny
                        style={{
                          backgroundColor: "#8B0000",
                          color: "#fff",
                          padding: 5,
                          width: "45%",
                          margin: "0 2.5px",
                        }}
                      >
                        <CardHeader title="Rejeitar pagamento" />
                        <Form
                          style={{ position: "relative" }}
                          ref={formRef}
                          onSubmit={responsePayment}
                        >
                          <Input
                            required
                            name="id"
                            type="hidden"
                            value={payment.id}
                          />
                          <Input
                            required
                            name="payment_status"
                            type="hidden"
                            value="canceled"
                          />

                          <Button
                            variant="contained"
                            style={{
                              margin: 10,
                              backgroundColor: "#8B0000",
                              color: "#fff",
                            }}
                            className={disabled ? classes.disabled : ""}
                            disabled={disabled}
                            type="submit"
                          >
                            Rejeitar
                          </Button>
                        </Form>
                      </ModalDeny>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Payments);
