// configura axios
import axios from "axios";
import { isExpired } from "react-jwt";
if (isExpired(localStorage.getItem("token"))) {
  localStorage.clear();
}

const api = axios.create({
  baseURL: "https://api.goodgain.gg",
  //baseURL: "http://localhost:8888",
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

export default api;
