import React, { useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import GilroyRegular from "../../assets/Gilroy-Regular.ttf";
import background from "../../assets/backgroundControls.jpg";
import logo from "../../assets/whiteLogo.png";
import { Form } from "@unform/web";
import Input from "../Form/input";
import Select from "../Form/select";
import api from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardHeader,
  Button,
  TablePagination,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import ModalConfirm from "../components/ModalConfirm";
import ModalImage from "../components/ModalImage";
import ModalText from "../components/ModalText";
import ModalDeny from "../components/ModalDeny";
import ModalIcon from "../components/ModalIcon";
import { faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import { ArrowBack } from "@material-ui/icons";
import { useQuery } from "../../hooks";

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100vw",
    backgroundRepeat: "repeat",
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  div: {
    maxWidth: "100vw",
    backgroundColor: "#e8e8e8",
    borderRadius: 30,
    overflowX: "auto",
  },
  content: {
    zIndex: 5,
    display: "flex",
    paddingTop: 30,
    paddingBottom: 30,
    flexDirection: "column",
  },
  input: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    borderRadius: 25,
    border: "none",
    color: "#383838",
    backgroundColor: "#fff",
    padding: 16,
    width: "100%",
    marginTop: "1%",
    marginBottom: "4%",
    position: "relative",
    WebkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
  },
  btn: {
    color: "#fff",
    borderWidth: 0,
    borderRadius: 40,
    fontSize: 16,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    backgroundImage: "linear-gradient(180deg,#e6007e 0%,#e94834 100%)",
    padding: "0.8em 1em",
    lineHeight: "1.7em",
    width: "100%",
    fontWeight: 600,
    margin: "5% 0",
  },
  "@font-face": {
    fontFamily: "Gilroy-regular",
    src: `url(${GilroyRegular})`,
  },
  logo: {
    width: "30%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  brand: {
    width: "15%",
    padding: "3%",
  },
  titleBrand: {
    padding: "1% 0",
    display: "flex",
    alignItems: "center",
    marginLeft: "-3%",
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    padding: "2% 1%",
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
    width: "100%",
  },
  label: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 16,
    paddingLeft: "2%",
  },
  forgotDiv: {
    width: "100%",
    textAlign: "right",
  },
  forgot: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    textDecoration: "none",
  },
  errorDiv: {
    width: "100%",
    textAlign: "right",
    margin: 0,
    position: "absolute",
  },
  error: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#D84141",
    fontSize: 14,
    textDecoration: "none",
    margin: 0,
    fontWeight: 600,
  },
  singIn: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
  },
  singInLink: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: 600,
  },
  iconPassword: {
    color: "#383838",
    right: 21,
    top: 8,
    height: 44,
    fontSize: 20,
    position: "absolute",
  },
  quit: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#e6007e",
    width: "fit-content",
    textAlign: "left",
    textDecoration: "none",
    cursor: "pointer",
    paddingLeft: 16,
    paddingRight: 16,
  },
  createLink: {
    padding: "2% 0",
    textDecoration: "none",
    display: "flex",
    justifyContent: "flex-end",
    width: "50%",
  },
  icon: {
    fontSize: 30,
  },
  results: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
  tableCell: {
    fontSize: 12,
  },
  divSelect: {
    position: "relative",
    width: "50%",
    "&:after": {
      content: "'\\f078'",
      font: "normal normal normal 17px/1 FontAwesome",
      color: "#383838",
      right: 21,
      top: 4,
      height: 34,
      padding: "12px 0px 0px 8px",
      position: "absolute",
      pointerEvents: "none",
    },
  },
});

function Wins(props) {
  const { classes } = props;
  const formRef = useRef(null);
  const [deleted, setDeleted] = React.useState(0);
  const [wins, setWins] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [status, setStatus] = React.useState("");
  const query = useQuery();
  const game_id = query.get("game_id");

  useEffect(() => {
    async function setValues() {
      var response = await api.get(
        `/match/listMatchesToConfirm?page=${page}&limit=${limit}&search=${search}&status=${status}${
          game_id ? `&game_id=${game_id}` : ""
        }`
      );
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].time = moment(response.data[i].created_at)
          .utcOffset("-0300")
          .format("HH:mm");
        response.data[i].created_at = moment(response.data[i].created_at)
          .utcOffset("-0300")
          .format("DD/MM/YYYY");

        response.data[i].award_value = response.data[
          i
        ].award_value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
        response.data[i].ticket_value = response.data[
          i
        ].ticket_value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      }
      setWins(response.data);
      setDisabled(false);
    }
    setValues();
  }, [deleted, page, limit, search, status, game_id]);

  async function confirmWin(data) {
    setDisabled(true);
    try {
      await api.post(`/match/confirmMatch/` + data.id, data);
    } catch (e) {
      setDisabled(false);
    }
    setDeleted(deleted + 1);
  }

  async function cancelWin(id) {
    setDisabled(true);
    try {
      await api.post(`/match/cancel/` + id);
    } catch (e) {
      setDisabled(false);
    }
    setDeleted(deleted + 1);
  }

  async function reverseWin(id) {
    setDisabled(true);
    try {
      await api.post(`/match/reverse/` + id);
    } catch (e) {
      setDisabled(false);
    }
    setDeleted(deleted + 1);
  }

  function statusColor(status) {
    if (
      status === "waiting_confirm" ||
      status === "waiting" ||
      status === "waiting_ok" ||
      status === "in_progress"
    ) {
      return "#edbb00";
    }
    if (status === "canceled") {
      return "red";
    }
    if (status === "confirmed") {
      return "green";
    }
    if (status === "waiting_reversal") {
      return "blue";
    }
    return "unset";
  }

  function statusText(status) {
    if (status === "waiting") {
      return "Aguardando";
    }
    if (status === "waiting_confirm") {
      return "Aguardando confirmação";
    }
    if (status === "waiting_ok") {
      return "Aguardando OK";
    }
    if (status === "in_progress") {
      return "Em progresso";
    }
    if (status === "canceled") {
      return "Negada";
    }
    if (status === "confirmed") {
      return "Aprovada";
    }
    if (status === "waiting_reversal") {
      return "Aguardando estorno";
    }
    return "Aguardando";
  }

  function statusHandleChange(e) {
    setStatus(e.target.value);
  }

  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <ResponsiveAppBar></ResponsiveAppBar>
      <div className={classes.div}>
        <div className={classes.content}>
          <Link className={classes.quit} to="/selectGames/pendwins">
            <ArrowBack fontSize="large" />
          </Link>
          <div>
            <Table size="small" style={{ whiteSpace: "nowrap", fontSize: 10 }}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.title}>Partidas pendentes</div>{" "}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <TextField
                      fullWidth
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          setPage(0);
                          setSearch(e.target.value);
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <FontAwesomeIcon icon={faSearch} />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Buscar Partida (ID, Nome, CPF, Telefone ou ID Console)"
                      variant="outlined"
                      style={{ backgroundColor: "#fff", minWidth: 200 }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCell}></TableCell>
                  <TableCell
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                    className={classes.tableCell}
                  >
                    <label
                      htmlFor="status"
                      style={{ marginRight: 10 }}
                      className={classes.label}
                    >
                      Status:
                    </label>
                    <div className={classes.divSelect}>
                      <select
                        defaultValue={status}
                        onChange={statusHandleChange}
                        className={classes.input}
                      >
                        <option value="">Todas</option>
                        <option value="waiting_reversal">
                          {statusText("waiting_reversal")}
                        </option>
                        <option value="waiting_confirm">
                          {statusText("waiting_confirm")}
                        </option>
                        <option value="in_progress">
                          {statusText("in_progress")}
                        </option>
                        <option value="waiting_ok">
                          {statusText("waiting_ok")}
                        </option>
                        <option value="waiting">{statusText("waiting")}</option>
                      </select>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Table style={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>ID</TableCell>
                  <TableCell className={classes.tableCell}>Prêmio</TableCell>
                  <TableCell className={classes.tableCell}>Ticket</TableCell>
                  <TableCell className={classes.tableCell}>Jogador 1</TableCell>
                  <TableCell className={classes.tableCell}>Jogador 2</TableCell>
                  <TableCell className={classes.tableCell}>Jogo</TableCell>
                  <TableCell className={classes.tableCell}>
                    Modo de Jogo
                  </TableCell>
                  <TableCell className={classes.tableCell}>Data</TableCell>
                  <TableCell className={classes.tableCell}>Hora</TableCell>
                  <TableCell className={classes.tableCell}>Status</TableCell>
                  <TableCell className={classes.tableCell}>
                    Resultados
                  </TableCell>
                  <TableCell className={classes.tableCell}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wins.map((win) => (
                  <TableRow
                    hover
                    key={win.id}
                    style={{ backgroundColor: "#fff" }}
                  >
                    <TableCell className={classes.tableCell}>
                      {win.id}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {win.award_value}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {win.ticket_value}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {!win.participants[0].user.active ? (
                        <>
                          <span style={{ color: "red" }}>BLOQUEADO</span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      <b>Nick GG:</b> {win.participants[0].user.nick_name}{" "}
                      <br />
                      <b>ID Console:</b>{" "}
                      {win.participants[0].user.platforms.find(
                        (plat) => plat.platform_id === win.platform_id
                      )
                        ? win.participants[0].user.platforms.find(
                            (plat) => plat.platform_id === win.platform_id
                          ).platform_identifier
                        : win.participants[0].user.crossPlayIds.find(
                            (cross) =>
                              cross.game_with_platform_id ===
                              win.room.gameMode.game_with_platform_id
                          )
                        ? win.participants[0].user.crossPlayIds.find(
                            (cross) =>
                              cross.game_with_platform_id ===
                              win.room.gameMode.game_with_platform_id
                          ).cross_play_id
                        : "-"}{" "}
                      <br />
                      <b>Telefone:</b> {win.participants[0].user.phone} <br />
                      <ModalText
                        style={{ width: "fit-content" }}
                        title="Infos"
                        className={classes.results}
                        text={
                          <div
                            style={{
                              width: "fit-content",
                              padding: 10,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <label className={classes.label}>
                              Nome: {win.participants[0].user.full_name}
                            </label>
                            <label className={classes.label}>
                              E-mail: {win.participants[0].user.email}
                            </label>
                            <label className={classes.label}>Nicknames:</label>
                            {win.participants[0].user.platforms.map(
                              (platform) => (
                                <label
                                  key={platform.id}
                                  className={classes.label}
                                >
                                  {platform.platform_identifier}
                                </label>
                              )
                            )}
                            {win.participants[0].user.crossPlayIds.map(
                              (cross) => (
                                <label key={cross.id} className={classes.label}>
                                  {cross.cross_play_id}
                                </label>
                              )
                            )}
                          </div>
                        }
                      />
                      <br />
                      <a
                        target="_blank"
                        href={`/userFinancials/${win.participants[0].user.id}`}
                        rel="noreferrer"
                      >
                        Extrato
                      </a>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {!win.participants[1].user.active ? (
                        <>
                          <span style={{ color: "red" }}>BLOQUEADO</span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      <b>Nick GG:</b> {win.participants[1].user.nick_name}{" "}
                      <br />
                      <b>ID Console:</b>{" "}
                      {win.participants[1].user.platforms.find(
                        (plat) => plat.platform_id === win.platform_id
                      )
                        ? win.participants[1].user.platforms.find(
                            (plat) => plat.platform_id === win.platform_id
                          ).platform_identifier
                        : win.participants[1].user.crossPlayIds.find(
                            (cross) =>
                              cross.game_with_platform_id ===
                              win.room.gameMode.game_with_platform_id
                          )
                        ? win.participants[1].user.crossPlayIds.find(
                            (cross) =>
                              cross.game_with_platform_id ===
                              win.room.gameMode.game_with_platform_id
                          ).cross_play_id
                        : "-"}{" "}
                      <br />
                      <b>Telefone:</b> {win.participants[1].user.phone} <br />
                      <ModalText
                        style={{ width: "fit-content" }}
                        title="Infos"
                        className={classes.results}
                        text={
                          <div
                            style={{
                              width: "fit-content",
                              padding: 10,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <label className={classes.label}>
                              Nome: {win.participants[1].user.full_name}
                            </label>
                            <label className={classes.label}>
                              E-mail: {win.participants[1].user.email}
                            </label>
                            <label className={classes.label}>Nicknames:</label>
                            {win.participants[1].user.platforms.map(
                              (platform) => (
                                <label
                                  key={platform.id}
                                  className={classes.label}
                                >
                                  {platform.platform_identifier}
                                </label>
                              )
                            )}
                            {win.participants[1].user.crossPlayIds.map(
                              (cross) => (
                                <label key={cross.id} className={classes.label}>
                                  {cross.cross_play_id}
                                </label>
                              )
                            )}
                          </div>
                        }
                      />
                      <br />
                      <a
                        target="_blank"
                        href={`/userFinancials/${win.participants[1].user.id}`}
                        rel="noreferrer"
                      >
                        Extrato
                      </a>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {win.room.gameMode.gameWithPlatform.game.name}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {win.room.gameMode.description}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {win.created_at}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {win.time}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <span
                        style={{
                          color: statusColor(win.status),
                        }}
                      >
                        {statusText(win.status)}
                      </span>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {/*  */}
                      <ModalText
                        style={{ width: "fit-content" }}
                        title="Resultados"
                        className={classes.results}
                        text={
                          <Table style={{ whiteSpace: "nowrap" }}>
                            <TableHead>
                              <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell>Nick GG</TableCell>
                                <TableCell>ID Console</TableCell>
                                <TableCell>Telefone</TableCell>
                                <TableCell>Foto</TableCell>
                                <TableCell>Declarou:</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {win.participants.map((participant) => (
                                <TableRow
                                  hover
                                  key={participant.id}
                                  style={{ backgroundColor: "#fff" }}
                                >
                                  <TableCell>
                                    {participant.user.full_name}
                                  </TableCell>
                                  <TableCell>
                                    {participant.user.nick_name}
                                  </TableCell>
                                  <TableCell>
                                    {participant.user.platforms.find(
                                      (plat) =>
                                        plat.platform_id === win.platform_id
                                    )
                                      ? participant.user.platforms.find(
                                          (plat) =>
                                            plat.platform_id === win.platform_id
                                        ).platform_identifier
                                      : participant.user.crossPlayIds.find(
                                          (cross) =>
                                            cross.game_with_platform_id ===
                                            win.room.gameMode
                                              .game_with_platform_id
                                        )
                                      ? participant.user.crossPlayIds.find(
                                          (cross) =>
                                            cross.game_with_platform_id ===
                                            win.room.gameMode
                                              .game_with_platform_id
                                        ).cross_play_id
                                      : "-"}
                                  </TableCell>
                                  <TableCell>
                                    {participant.user.phone}
                                  </TableCell>
                                  <TableCell>
                                    {participant.evidence &&
                                    participant.evidence !== "loser" ? (
                                      <ModalImage
                                        className={classes.icon}
                                        img={participant.evidence_url}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {participant.evidence &&
                                    participant.evidence !== "loser" ? (
                                      <span style={{ color: "green" }}>
                                        Ganhador
                                      </span>
                                    ) : participant.evidence !== "loser" ? (
                                      <span>-</span>
                                    ) : (
                                      <span style={{ color: "red" }}>
                                        Perdedor
                                      </span>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        }
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <ModalConfirm
                        style={{
                          backgroundColor: "#17882c",
                          color: "#fff",
                          padding: 5,
                          width: 34,
                          margin: "0 4px",
                        }}
                      >
                        <CardHeader
                          subheader="Selecione o jogador vencedor."
                          title="Confirmar ganhador"
                        />
                        <Form
                          style={{ position: "relative" }}
                          ref={formRef}
                          onSubmit={confirmWin}
                        >
                          <Select
                            style={{ backgroundColor: "#e8e8e8" }}
                            defaultValue=""
                            className={classes.input}
                            required
                            name="winner"
                          >
                            <option value="" disabled>
                              Selecione o ganhador
                            </option>
                            <option value={win.participants[0].user.id}>
                              {win.participants[0].user.platforms.find(
                                (plat) => plat.platform_id === win.platform_id
                              )
                                ? win.participants[0].user.platforms.find(
                                    (plat) =>
                                      plat.platform_id === win.platform_id
                                  ).platform_identifier
                                : win.participants[0].user.crossPlayIds.find(
                                    (cross) =>
                                      cross.game_with_platform_id ===
                                      win.room.gameMode.game_with_platform_id
                                  )
                                ? win.participants[0].user.crossPlayIds.find(
                                    (cross) =>
                                      cross.game_with_platform_id ===
                                      win.room.gameMode.game_with_platform_id
                                  ).cross_play_id
                                : "-"}
                            </option>
                            <option value={win.participants[1].user.id}>
                              {win.participants[1].user.platforms.find(
                                (plat) => plat.platform_id === win.platform_id
                              )
                                ? win.participants[1].user.platforms.find(
                                    (plat) =>
                                      plat.platform_id === win.platform_id
                                  ).platform_identifier
                                : win.participants[1].user.crossPlayIds.find(
                                    (cross) =>
                                      cross.game_with_platform_id ===
                                      win.room.gameMode.game_with_platform_id
                                  )
                                ? win.participants[1].user.crossPlayIds.find(
                                    (cross) =>
                                      cross.game_with_platform_id ===
                                      win.room.gameMode.game_with_platform_id
                                  ).cross_play_id
                                : "-"}
                            </option>
                          </Select>
                          <Input
                            required
                            name="id"
                            type="hidden"
                            value={win.id}
                          />
                          <Button
                            variant="contained"
                            style={{
                              margin: 10,
                              backgroundColor: "#17882c",
                              color: "#fff",
                            }}
                            className={disabled ? classes.disabled : ""}
                            disabled={disabled}
                            type="submit"
                          >
                            Confirmar
                          </Button>
                        </Form>
                      </ModalConfirm>
                      <ModalDeny
                        style={{
                          backgroundColor: "#8B0000",
                          color: "#fff",
                          padding: 5,
                          width: 34,
                          margin: "0 4px",
                        }}
                      >
                        <CardHeader
                          subheader="Tem certeza que deseja negar a partida? O valor não será estornado."
                          title="Negar partida."
                        />

                        <Button
                          variant="contained"
                          style={{
                            margin: 10,
                            backgroundColor: "#8B0000",
                            color: "#fff",
                          }}
                          className={disabled ? classes.disabled : ""}
                          disabled={disabled}
                          onClick={() => cancelWin(win.id)}
                          type="submit"
                        >
                          Negar
                        </Button>
                      </ModalDeny>
                      <ModalIcon
                        style={{
                          backgroundColor: "#2543ca",
                          color: "#fff",
                          padding: 5,
                          width: 34,
                          margin: "0 4px",
                        }}
                        icon={faMoneyBillTransfer}
                        iconWidth={20}
                      >
                        <CardHeader
                          subheader="Tem certeza que deseja cancelar/estornar a partida? O valor da partida será estornado para os jogadores."
                          title="Cancelar/Estornar partida."
                        />

                        <Button
                          variant="contained"
                          style={{
                            margin: 10,
                            backgroundColor: "#2543ca",
                            color: "#fff",
                          }}
                          className={disabled ? classes.disabled : ""}
                          disabled={disabled}
                          onClick={() => reverseWin(win.id)}
                          type="submit"
                        >
                          Cancelar/Estornar
                        </Button>
                      </ModalIcon>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={-1}
              page={page}
              labelRowsPerPage="Linhas por página:"
              labelDisplayedRows={({ page }) => {
                return `Página: ${page + 1}`;
              }}
              onPageChange={(e, val) => setPage(val)}
              rowsPerPage={limit}
              onRowsPerPageChange={({ target }) => {
                setLimit(target.value);
                setPage(0);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Wins);
