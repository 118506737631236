import React, { useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import GilroyRegular from "../../assets/Gilroy-Regular.ttf";
import background from "../../assets/backgroundControls.jpg";
import logo from "../../assets/whiteLogo.png";
import brand from "../../assets/marcaLogo.png";
import { Form } from "@unform/web";
import Input from "../Form/input";
import api from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100vw",
    backgroundRepeat: "repeat",
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  div: {
    width: "35%",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  content: {
    backgroundColor: "#e8e8e8",
    padding: 30,
    margin: "auto",
    backgroundSize: "cover",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    borderRadius: 30,
    zIndex: 5,
    display: "flex",
    flexDirection: "column",
  },
  input: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    borderRadius: 25,
    border: "none",
    color: "#383838",
    backgroundColor: "#fff",
    padding: 16,
    width: "100%",
    marginTop: "1%",
    marginBottom: "4%",
    position: "relative",
  },
  btn: {
    color: "#fff",
    borderWidth: 0,
    borderRadius: 40,
    fontSize: 16,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    backgroundImage: "linear-gradient(180deg,#e6007e 0%,#e94834 100%)",
    padding: "0.8em 1em",
    lineHeight: "1.7em",
    width: "100%",
    fontWeight: 600,
    margin: "5% 0",
  },
  "@font-face": {
    fontFamily: "Gilroy-regular",
    src: `url(${GilroyRegular})`,
  },
  logo: {
    width: "30%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  brand: {
    width: "15%",
    padding: "3%",
  },
  titleBrand: {
    padding: "1% 0",
    display: "flex",
    alignItems: "center",
    marginLeft: "-3%",
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
  },
  label: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 16,
    paddingLeft: "2%",
  },
  forgotDiv: {
    width: "100%",
    textAlign: "right",
  },
  forgot: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    textDecoration: "none",
  },
  errorDiv: {
    width: "100%",
    textAlign: "right",
    margin: 0,
    position: "absolute",
  },
  error: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#D84141",
    fontSize: 14,
    textDecoration: "none",
    margin: 0,
    fontWeight: 600,
  },
  singIn: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
  },
  singInLink: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: 600,
  },
  iconPassword: {
    color: "#383838",
    right: 16,
    top: 16,
    height: 24,
    position: "absolute",
  },
});

function DashboardAuth(props) {
  const { classes } = props;
  const formRef = useRef(null);
  const [error, setError] = React.useState(false);
  const [password, setPassword] = React.useState("password");
  function changePassword() {
    if (password === "password") {
      setPassword("text");
    } else {
      setPassword("password");
    }
  }
  async function handleSubmit(data) {
    try {
      await api.post(`/authDashboard`, data);
      localStorage.removeItem("isDashboardAuthorized");
      localStorage.setItem("isDashboardAuthorized", true);
      window.location.assign("/dashboard");
    } catch (err) {
      setError(true);
    }
  }
  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <div className={classes.div}>
        <div className={classes.content}>
          <div className={classes.titleBrand}>
            <img src={brand} alt="brand" className={classes.brand} />
            <span className={classes.title}>Dashboard</span>{" "}
          </div>
          <Form
            style={{ position: "relative" }}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            {error ? (
              <div className={classes.errorDiv}>
                <p className={classes.error}>Senha incorreta.</p>
              </div>
            ) : (
              ""
            )}
            <label className={classes.label}>Senha</label>
            <div style={{ position: "relative" }}>
              <Input
                className={classes.input}
                required
                name="password"
                type={password}
                placeholder="Informe sua senha"
              />
              <FontAwesomeIcon
                onClick={changePassword}
                className={classes.iconPassword}
                icon={faEye}
              />
            </div>
            <button className={classes.btn} type="submit">
              Entrar
            </button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(DashboardAuth);
