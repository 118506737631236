import React from "react";
import UserFinancials from "../components/views/UserFinancials";
import withRoot from "../components/withRoot";

function Index() {
  return (
    <React.Fragment>
      <UserFinancials />
    </React.Fragment>
  );
}

export default withRoot(Index);
