import React, { useEffect, useMemo } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import { Grid, TextField } from "@material-ui/core";
import IndicatorComponent from "../components/IndicatorComponent";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import api from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateBack } from "@fortawesome/free-solid-svg-icons";

const styles = (theme) => ({
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    padding: "2vw",
  },
  topBar: {
    height: "10vh",
    paddingLeft: 20,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  quit: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#e6007e",
    width: "fit-content",
    textAlign: "left",
    textDecoration: "none",
    cursor: "pointer",
  },
  dateInput: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 40,
    backgroundColor: "#fff",
    padding: 15,
    borderRadius: 20,
  },
  reload: {
    backgroundColor: "#fff",
    height: 30,
    width: 30,
    cursor: "pointer",
    padding: 10,
    fontSize: 12,
    borderRadius: 40,
    marginTop: 14,
  },
});

function Dashboard(props) {
  const { classes } = props;
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [transactions, setTransactions] = React.useState([]);
  const [startDateValue, setStartDateValue] = React.useState(new Date());
  const [endDateValue, setEndDateValue] = React.useState(new Date());
  const [financialLoading, setFinancialLoading] = React.useState(true);
  const [usersLoading, setUsersLoading] = React.useState(true);
  const [matchesLoading, setMatchesLoading] = React.useState(true);
  const [usersByPlatformAndGameLoading, setUsersByPlatformAndGameLoading] =
    React.useState(true);
  const [walletLoading, setWalletLoading] = React.useState(true);
  const [walletValues, setWalletValues] = React.useState({
    drawable_balance: 0,
    no_drawable_balance: 0,
    total_balance: 0,
  });
  const [reload, setReload] = React.useState(true);
  const [totalUsers, setTotalUsers] = React.useState(0);
  const [users, setUsers] = React.useState([]);
  const [totalWithdrawUsers, setTotalWithdrawUsers] = React.useState(0);
  const [totalDepositUsers, setTotalDepositUsers] = React.useState(0);
  const [countActiveUsers, setCountActiveUsers] = React.useState(0);
  const [countActive5MatchesUsers, setCountActive5MatchesUsers] =
    React.useState(0);
  const [totalUserByPlatformsAndGames, setTotalUserByPlatformsAndGames] =
    React.useState([]);
  const [totalMatches, setTotalMatches] = React.useState(0);
  const [totalMatchesConfirmed, setTotalMatchesConfirmed] = React.useState(0);
  const [totalMatchesCanceled, setTotalMatchesCanceled] = React.useState(0);
  const [totalMatchesDenied, setTotalMatchesDenied] = React.useState(0);
  const [matchesBadFeedbacks, setMatchesBadFeedbacks] = React.useState([]);
  const [matchesGoodFeedbacks, setMatchesGoodFeedbacks] = React.useState([]);
  const [totalMatchesByGameAndConsole, setTotalMatchesByGameAndConsole] =
    React.useState([]);
  const [
    loadingTotalMatchesByGameAndConsole,
    setLoadingTotalMatchesByGameAndConsole,
  ] = React.useState(true);

  useEffect(() => {
    async function setFinancialValues() {
      setFinancialLoading(true);

      let { data } = await api.put(`/wallet/listTransactions`, {
        startDate,
        endDate,
      });

      setTransactions(data);
      setFinancialLoading(false);
    }
    async function setMatchesValues() {
      setMatchesLoading(true);

      let { data } = await api.get(
        `/match/count?startDate=${startDate}&endDate=${endDate}`
      );

      setTotalMatches(data);

      let { data: dataConfirmed } = await api.get(
        `/match/count?startDate=${startDate}&endDate=${endDate}&status=confirmed`
      );

      setTotalMatchesConfirmed(dataConfirmed);

      let { data: dataCanceled } = await api.get(
        `/match/count?startDate=${startDate}&endDate=${endDate}&status=canceled`
      );

      setTotalMatchesCanceled(dataCanceled);

      let { data: dataDenied } = await api.get(
        `/match/count?startDate=${startDate}&endDate=${endDate}&status=denied`
      );

      setTotalMatchesDenied(dataDenied);

      let { data: dataBadFeedbacks } = await api.get(
        `/match/listFeedbacks?startDate=${startDate}&endDate=${endDate}&like=false`
      );

      setMatchesBadFeedbacks(dataBadFeedbacks);

      let { data: dataGoodFeedbacks } = await api.get(
        `/match/listFeedbacks?startDate=${startDate}&endDate=${endDate}&like=true`
      );

      setMatchesGoodFeedbacks(dataGoodFeedbacks);

      setMatchesLoading(false);
    }
    async function setUsersValues() {
      setUsersLoading(true);

      let users = await api.get(`/users/list`);
      setUsers(users.data);
      let count = await api.get(
        `/users/count?startDate=${startDate}&endDate=${endDate}`
      );

      let countTotalWithdraw = await api.get(
        `/wallet/countTotalUsersByPaymentType?startDate=${startDate}&endDate=${endDate}&paymentTypeCode=withdraw`
      );

      let countTotalDeposit = await api.get(
        `/wallet/countTotalUsersByPaymentType?startDate=${startDate}&endDate=${endDate}&paymentTypeCode=credit&origin_type=bank`
      );

      setTotalWithdrawUsers(countTotalWithdraw.data);

      setTotalDepositUsers(countTotalDeposit.data);

      let countActive = await api.get(
        `/match/countTotalActiveUsers?startDate=${startDate}&endDate=${endDate}`
      );
      let countActive5matches = await api.get(
        `/match/countTotalActiveUsers?startDate=${startDate}&endDate=${endDate}&minMatches=5`
      );

      setCountActiveUsers(countActive.data);
      setCountActive5MatchesUsers(countActive5matches.data);

      setTotalUsers(count.data);
      setUsersLoading(false);
    }
    async function setMatchesByPlatformsAndGames() {
      setLoadingTotalMatchesByGameAndConsole(true);

      let totalPlatformsGamesMatches = [];

      const { data: platforms } = await api.get(`/platforms`);

      for (let platform of platforms) {
        let { data: matchesByPlatform } = await api.get(
          `/match/count?startDate=${startDate}&endDate=${endDate}&platform_id=${platform.id}`
        );
        totalPlatformsGamesMatches.push({
          description: `Total partidas console ${platform.name}:*`,
          helpText: `Soma do total das partidas do console ${platform.name} realizadas no período. (filtro por data da partida)`,
          value: matchesByPlatform,
        });
      }

      const { data: games } = await api.get(`/games`);

      for (let game of games) {
        let { data: matchesByGame } = await api.get(
          `/match/count?startDate=${startDate}&endDate=${endDate}&game_id=${game.id}`
        );
        totalPlatformsGamesMatches.push({
          description: `Total partidas jogo ${game.name}:*`,
          helpText: `Soma do total das partidas do jogo ${game.name} realizadas no período. (filtro por data da partida)`,
          value: matchesByGame,
        });
      }

      setTotalMatchesByGameAndConsole(totalPlatformsGamesMatches);
      setLoadingTotalMatchesByGameAndConsole(false);
    }
    function setValues() {
      if (startDate && endDate && reload) {
        setReload(false);
        setFinancialValues();
        setUsersValues();
        setMatchesValues();
        setMatchesByPlatformsAndGames();
      }
    }
    setValues();
  }, [startDate, endDate, reload]);

  useEffect(() => {
    async function setWalletTotalValues() {
      setWalletLoading(true);

      var { data } = await api.get(`/wallet/getTotalValues`);

      setWalletValues(data);
      setWalletLoading(false);
    }
    async function setTotalUserByPlatformsAndGamesValues() {
      setUsersByPlatformAndGameLoading(true);

      let totalPlatformsGamesUsers = [];

      const { data: platforms } = await api.get(`/platforms`);

      for (let platform of platforms) {
        let { data: countPlatformUsers } = await api.get(
          `/match/countTotalUsersByGameAndConsole?platform_id=${platform.id}`
        );
        totalPlatformsGamesUsers.push({
          description: `Total usuários console ${platform.name}:*`,
          helpText: `Soma do total dos usuários que participaram de alguma partida aprovada do console ${platform.name}. (desconsidera período)`,
          value: countPlatformUsers,
        });
      }

      const { data: games } = await api.get(`/games`);

      for (let game of games) {
        let { data: countGameUsers } = await api.get(
          `/match/countTotalUsersByGameAndConsole?game_id=${game.id}`
        );
        totalPlatformsGamesUsers.push({
          description: `Total usuários jogo ${game.name}:*`,
          helpText: `Soma do total dos usuários que participaram de alguma partida aprovada do jogo ${game.name}. (desconsidera período)`,
          value: countGameUsers,
        });
      }

      setTotalUserByPlatformsAndGames(totalPlatformsGamesUsers);
      setUsersByPlatformAndGameLoading(false);
    }
    setTotalUserByPlatformsAndGamesValues();
    setWalletTotalValues();
    let startTime = removeDays(new Date(), 0);
    let endTime = addDays(new Date(), 0);
    setStartDateValue(startTime);
    setEndDateValue(endTime);
    endTime = addDays(endTime, 1);
    setStartDate(
      startTime.getFullYear() +
        "-" +
        ("0" + (startTime.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + startTime.getDate()).slice(-2)
    );
    setEndDate(
      endTime.getFullYear() +
        "-" +
        ("0" + (endTime.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + endTime.getDate()).slice(-2)
    );
  }, []);

  function addDays(date, days) {
    var newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  function removeDays(date, days) {
    var newDate = new Date(date);
    newDate.setDate(newDate.getDate() - days);
    return newDate;
  }

  function handleChangeStartDate(startTime) {
    setStartDateValue(startTime);
    setStartDate(
      startTime.getFullYear() +
        "-" +
        ("0" + (startTime.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + startTime.getDate()).slice(-2)
    );
  }

  function handleChangeEndDate(endTime) {
    setEndDateValue(endTime);
    endTime = addDays(endTime, 1);
    setEndDate(
      endTime.getFullYear() +
        "-" +
        ("0" + (endTime.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + endTime.getDate()).slice(-2)
    );
  }

  // ---------------------------------- TRANSAÇÕES ----------------------------------
  let financialData = useMemo(() => {
    let totalMatchTax = 0;
    let totalTournamentTax = 0;
    let totalBonus = 0;
    let totalDeposit = 0;
    let totalWithdraw = 0;
    let totalWithdrawTax = 0;
    let totalPaidTax = 0;
    transactions.forEach((transaction) => {
      if (transaction.paymentStatus.code === "paid") {
        if (
          transaction.origin_type === "match" &&
          transaction.paymentType.code === "win" &&
          transaction.tax
        ) {
          totalMatchTax = totalMatchTax + -parseFloat(transaction.tax);
        }
        if (
          transaction.origin_type === "tournament" &&
          transaction.paymentType.code === "win" &&
          transaction.tax
        ) {
          totalTournamentTax =
            totalTournamentTax + -parseFloat(transaction.tax);
        }
        if (
          transaction.paymentType.code === "bonus" &&
          transaction.payment_value > 0 &&
          !transaction.origin_type &&
          !transaction.origin_id
        ) {
          totalBonus = totalBonus + parseFloat(transaction.payment_value);
        }
        if (transaction.paymentType.code === "credit" && transaction.bank) {
          totalDeposit = totalDeposit + parseFloat(transaction.payment_value);
          totalPaidTax =
            totalPaidTax +
            parseFloat(transaction.tax_deducted ? transaction.tax_deducted : 0);
        }
        if (transaction.paymentType.code === "withdraw") {
          totalWithdraw =
            totalWithdraw + -parseFloat(transaction.payment_value);
          totalWithdrawTax =
            totalWithdrawTax +
            parseFloat(transaction.tax ? transaction.tax : 0);
          totalPaidTax =
            totalPaidTax +
            parseFloat(transaction.tax_deducted ? transaction.tax_deducted : 0);
        }
      }
    });
    return [
      {
        description: "Total taxas partidas:",
        helpText:
          "Soma do total de taxas coletadas no prêmio de partidas realizadas no período. (filtro por data da transação)",
        value: parseFloat(totalMatchTax.toFixed(2)).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
      },
      {
        description: "Total taxas torneios:",
        helpText:
          "Soma do total de taxas coletadas nos prêmios de torneios realizados no período. (filtro por data da transação)",
        value: parseFloat(totalTournamentTax.toFixed(2)).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        ),
      },
      {
        description: "Total taxas:",
        helpText:
          "Soma do total de taxas coletadas nos prêmios de torneios e partidas realizadas no período. (filtro por data da transação)",
        value: parseFloat(
          (totalTournamentTax + totalMatchTax).toFixed(2)
        ).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
      },
      {
        description: "Total bônus pago:",
        helpText:
          "Soma total dos bônus concedidos aos usuários no período. (filtro por data da transação)",
        value: parseFloat(totalBonus.toFixed(2)).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
        backgroundColor: "#e6007e",
      },
      {
        description: "Total depósitos:",
        helpText:
          "Soma total de depósitos realizados no período. (filtro por data da transação)",
        value: parseFloat(totalDeposit.toFixed(2)).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
      },
      {
        description: "Total saques:",
        helpText:
          "Soma total de saques realizados no período. (filtro por data da transação)",
        value: parseFloat(totalWithdraw.toFixed(2)).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
        backgroundColor: "#e6007e",
      },
      {
        description: "Total taxas saques:",
        helpText:
          "Soma total das taxas cobradas nos saques realizados no período. (filtro por data da transação)",
        value: parseFloat(totalWithdrawTax.toFixed(2)).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
      },
      {
        description: "Total taxas pagas:",
        helpText:
          "Soma total das taxas pagas para a plataforma WePayOut. (filtro por data da transação)",
        value: parseFloat(totalPaidTax.toFixed(2)).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
        backgroundColor: "#e6007e",
      },
    ];
  }, [transactions]);
  financialData = useMemo(() => {
    return [
      ...financialData,
      {
        description: "Total créditos sacáveis:*",
        helpText:
          "Soma total dos créditos sacáveis nas carteiras dos usuários no momento. (desconsidera período)",
        value: parseFloat(
          walletValues.drawable_balance.toFixed(2)
        ).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
        backgroundColor: "#e6007e",
      },
      {
        description: "Total créditos não sacáveis:*",
        helpText:
          "Soma total dos créditos não sacáveis nas carteiras dos usuários no momento. (desconsidera período)",
        value: parseFloat(
          walletValues.no_drawable_balance.toFixed(2)
        ).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
      },
      {
        description: "Total créditos:*",
        helpText:
          "Soma total dos créditos sacáveis e não sacáveis nas carteiras dos usuários no momento. (desconsidera período)",
        value: parseFloat(walletValues.total_balance.toFixed(2)).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        ),
      },
    ];
  }, [walletValues, financialData]);

  // ---------------------------------- USUÁRIOS ----------------------------------

  let userWithBalance = 0;
  let userWithNoDrawableBalance = 0;
  let userWithDrawableBalance = 0;

  users.forEach((user) => {
    if (user.wallet.total_balance > 0) {
      userWithBalance = userWithBalance + 1;
    }
    if (user.wallet.total_balance - user.wallet.drawable_balance > 0) {
      userWithNoDrawableBalance = userWithNoDrawableBalance + 1;
    }
    if (user.wallet.drawable_balance > 0) {
      userWithDrawableBalance = userWithDrawableBalance + 1;
    }
  });

  const usersData = [
    {
      description: "Total usuários inscritos:",
      helpText:
        "Soma do total de usuários inscritos no período. (filtro por data da inscrição)",
      value: totalUsers,
    },
    {
      description: "Total usuários que fizeram depósito:",
      helpText:
        "Soma do total dos usuários que fizeram algum depósito no período. (filtro por data da transação)",
      value: totalDepositUsers,
    },
    {
      description: "Total usuários que fizeram saque:",
      helpText:
        "Soma do total dos usuários que fizeram algum saque no período. (filtro por data da transação)",
      value: totalWithdrawUsers,
    },
    {
      description: "Total usuários ativos:",
      helpText:
        "Soma do total dos usuários que participaram de ao menos uma partida confirmada no período. (filtro por data da partida)",
      value: countActiveUsers,
    },
    {
      description: "Total usuários ativos recorrentes:",
      helpText:
        "Soma do total dos usuários que participaram de ao menos 5 partidas confirmadas no período. (filtro por data da partida)",
      value: countActive5MatchesUsers,
    },
    {
      description: "Total usuários com saldo:*",
      helpText:
        "Soma do total dos usuários com saldo sacável ou não na carteira no momento. (desconsidera período)",
      value: userWithBalance,
    },
    {
      description: "Total usuários com saldo sacável:*",
      helpText:
        "Soma do total dos usuários com saldo sacável na carteira no momento. (desconsidera período)",
      value: userWithDrawableBalance,
    },
    {
      description: "Total usuários com saldo não sacável:*",
      helpText:
        "Soma do total dos usuários com saldo não sacável na carteira no momento. (desconsidera período) (usuário pode ter saldo sacável também)",
      value: userWithNoDrawableBalance,
    },
  ];

  // ---------------------------------- Partidas ----------------------------------

  const matchesData = [
    {
      description: "Total partidas:",
      helpText:
        "Soma do total das partidas realizadas no período. (filtro por data da partida)",
      value: totalMatches,
    },
    {
      description: "Total partidas aprovadas:",
      helpText:
        "Soma do total das partidas realizadas e aprovadas no período. (filtro por data da partida)",
      value: totalMatchesConfirmed,
    },
    {
      description: "Total partidas canceladas/estornadas:",
      helpText:
        "Soma do total das partidas canceladas/estornadas no período. (filtro por data da partida)",
      value: totalMatchesCanceled,
    },
    {
      description: "Total partidas negadas:",
      helpText:
        "Soma do total das partidas negadas no período. (filtro por data da partida)",
      value: totalMatchesDenied,
    },
    {
      description: "Total feedbacks positivos:",
      helpText:
        "Soma total dos feedbacks positivos das partidas no período. (filtro por data do feedback)",
      value: matchesGoodFeedbacks.length,
    },
    {
      description: "Total feedbacks negativos:",
      helpText:
        "Soma total dos feedbacks negativos das partidas no período. (filtro por data do feedback)",
      value: matchesBadFeedbacks.length,
      backgroundColor: "#e6007e",
    },
    {
      description: "Porcentagem feedbacks positivos:",
      helpText:
        "Porcentagem dos feedbacks positivos das partidas no período. (filtro por data do feedback)",
      value: (
        (
          (matchesGoodFeedbacks.length /
            (matchesGoodFeedbacks.length + matchesBadFeedbacks.length)) *
          100
        ).toFixed(2) + "%"
      ).replace(".", ","),
    },
    {
      description: "Porcentagem feedbacks negativos:",
      helpText:
        "Porcentagem dos feedbacks negativos das partidas no período. (filtro por data do feedback)",
      value: (
        (
          (matchesBadFeedbacks.length /
            (matchesGoodFeedbacks.length + matchesBadFeedbacks.length)) *
          100
        ).toFixed(2) + "%"
      ).replace(".", ","),
      backgroundColor: "#e6007e",
    },
  ];

  return (
    <>
      <div className={classes.topBar}>
        <Link className={classes.quit} to="/panel">
          <ArrowBack fontSize="large" />
        </Link>
      </div>
      <div className={classes.content}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div style={{ display: "flex" }}>
            <div className={classes.dateInput}>
              <DesktopDatePicker
                label="De:"
                inputFormat="dd/MM/yyyy"
                value={startDateValue}
                onChange={handleChangeStartDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>

            <div className={classes.dateInput}>
              <DesktopDatePicker
                label="Até:"
                inputFormat="dd/MM/yyyy"
                value={endDateValue}
                onChange={handleChangeEndDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <FontAwesomeIcon
              className={classes.reload}
              onClick={() => setReload(true)}
              icon={faRotateBack}
            />
          </div>
        </LocalizationProvider>
        <Grid container spacing={3}>
          <IndicatorComponent
            loading={financialLoading || walletLoading}
            data={financialData}
            title="Financeiro"
            showSubtitle
          />
          <IndicatorComponent
            loading={usersLoading}
            data={usersData}
            title="Usuários"
            showSubtitle
          />
          <IndicatorComponent
            loading={usersByPlatformAndGameLoading}
            data={totalUserByPlatformsAndGames}
            title="Usuários por Console e Jogo"
            showSubtitle
          />
          <IndicatorComponent
            loading={matchesLoading}
            data={matchesData}
            title="Partidas"
          />
          <IndicatorComponent
            loading={loadingTotalMatchesByGameAndConsole}
            data={totalMatchesByGameAndConsole}
            title="Partidas por Console e Jogo"
          />
        </Grid>
      </div>
    </>
  );
}

export default withStyles(styles)(Dashboard);
