import React from 'react';
import ConsoleGame from '../components/views/ConsoleGame';
import withRoot from '../components/withRoot';

function Index() {

  return (
    <React.Fragment>
      <ConsoleGame />
    </React.Fragment>
  );
}

export default withRoot(Index);
