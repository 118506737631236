import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import GilroyRegular from "../../assets/Gilroy-Regular.ttf";
import background from "../../assets/backgroundControls.jpg";
import logo from "../../assets/whiteLogo.png";
import api from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import ModalImage from "../components/ModalImage";
import { ArrowBack } from "@material-ui/icons";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100vw",
    backgroundRepeat: "repeat",
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  div: {
    maxWidth: "100vw",
    backgroundColor: "#e8e8e8",
    borderRadius: 30,
    overflowX: "auto",
  },
  content: {
    zIndex: 5,
    display: "flex",
    paddingTop: 30,
    paddingBottom: 30,
    flexDirection: "column",
  },
  input: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    borderRadius: 25,
    border: "none",
    color: "#383838",
    backgroundColor: "#e8e8e8",
    padding: 16,
    width: "100%",
    marginTop: "1%",
    marginBottom: "4%",
    position: "relative",
  },
  btn: {
    color: "#fff",
    borderWidth: 0,
    borderRadius: 40,
    fontSize: 16,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    backgroundImage: "linear-gradient(180deg,#e6007e 0%,#e94834 100%)",
    padding: "0.8em 1em",
    lineHeight: "1.7em",
    width: "100%",
    fontWeight: 600,
    margin: "5% 0",
  },
  "@font-face": {
    fontFamily: "Gilroy-regular",
    src: `url(${GilroyRegular})`,
  },
  logo: {
    width: "30%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  brand: {
    width: "15%",
    padding: "3%",
  },
  titleBrand: {
    padding: "1% 0",
    display: "flex",
    alignItems: "center",
    marginLeft: "-3%",
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    padding: "2% 1%",
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
    width: "100%",
  },
  label: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 16,
    paddingLeft: "2%",
  },
  forgotDiv: {
    width: "100%",
    textAlign: "right",
  },
  forgot: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    textDecoration: "none",
  },
  errorDiv: {
    width: "100%",
    textAlign: "right",
    margin: 0,
    position: "absolute",
  },
  error: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#D84141",
    fontSize: 14,
    textDecoration: "none",
    margin: 0,
    fontWeight: 600,
  },
  singIn: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
  },
  singInLink: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: 600,
  },
  iconPassword: {
    color: "#383838",
    right: 21,
    top: 8,
    height: 44,
    fontSize: 20,
    position: "absolute",
  },
  quit: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#e6007e",
    width: "fit-content",
    textAlign: "left",
    textDecoration: "none",
    cursor: "pointer",
    paddingLeft: 16,
    paddingRight: 16,
  },
  createLink: {
    padding: "2% 0",
    textDecoration: "none",
    display: "flex",
    justifyContent: "flex-end",
    width: "50%",
  },
  icon: {
    fontSize: 30,
  },
});

function AllDeposits(props) {
  const { classes } = props;
  const [deposits, setDeposits] = React.useState([]);
  const [fixedDeposits, setFixedDeposits] = React.useState([]);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [startDateValue, setStartDateValue] = React.useState(new Date());
  const [endDateValue, setEndDateValue] = React.useState(new Date());

  useEffect(() => {
    async function setValues() {
      if (startDate && endDate) {
        var { data } = await api.put(`/wallet/listTransactions`, {
          paymentStatusCode: "paid",
          paymentTypeCode: "withdraw",
          startDate,
          endDate,
        });
        for (let i = 0; i < data.length; i++) {
          data[i].created_at = new Date(data[i].created_at).toLocaleDateString(
            "pt-br"
          );
          data[i].payment_value = Math.abs(
            data[i].payment_value
          ).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
          data[i].wallet.user.cpf = mCPF(data[i].wallet.user.cpf);
          data[i].metadata = JSON.parse(data[i].metadata);
        }
        setDeposits(data);
        setFixedDeposits(data);
      }
    }
    setValues();
  }, [startDate, endDate]);

  useEffect(() => {
    let startTime = removeDays(new Date(), 6);
    let endTime = addDays(new Date(), 1);
    setStartDateValue(startTime);
    setEndDateValue(endTime);
    endTime = addDays(endTime, 1);
    setStartDate(
      startTime.getFullYear() +
        "-" +
        ("0" + (startTime.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + startTime.getDate()).slice(-2)
    );
    setEndDate(
      endTime.getFullYear() +
        "-" +
        ("0" + (endTime.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + endTime.getDate()).slice(-2)
    );
  }, []);

  function handleChangeStartDate(startTime) {
    setStartDateValue(startTime);
    setStartDate(
      startTime.getFullYear() +
        "-" +
        ("0" + (startTime.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + startTime.getDate()).slice(-2)
    );
  }
  function handleChangeEndDate(endTime) {
    setEndDateValue(endTime);
    endTime = addDays(endTime, 1);
    setEndDate(
      endTime.getFullYear() +
        "-" +
        ("0" + (endTime.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + endTime.getDate()).slice(-2)
    );
  }

  function addDays(date, days) {
    var newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }
  function removeDays(date, days) {
    var newDate = new Date(date);
    newDate.setDate(newDate.getDate() - days);
    return newDate;
  }

  function mCPF(cpf) {
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  }
  function changeSearch(e) {
    if (e.key === "Enter") {
      let keyword = e.target.value;
      let filtered = fixedDeposits.filter(
        (deposit) =>
          deposit.payment_value.toUpperCase().includes(keyword.toUpperCase()) ||
          deposit.wallet.user.nick_name
            .toUpperCase()
            .includes(keyword.toUpperCase()) ||
          deposit.wallet.user.full_name
            .toUpperCase()
            .includes(keyword.toUpperCase()) ||
          deposit.wallet.user.email
            .toUpperCase()
            .includes(keyword.toUpperCase()) ||
          deposit.wallet.user.phone
            .toUpperCase()
            .includes(keyword.toUpperCase()) ||
          deposit.wallet.user.cpf
            .toUpperCase()
            .includes(keyword.toUpperCase()) ||
          deposit.metadata.bank.toUpperCase().includes(keyword.toUpperCase()) ||
          deposit.metadata.agency
            .toUpperCase()
            .includes(keyword.toUpperCase()) ||
          deposit.metadata.account
            .toUpperCase()
            .includes(keyword.toUpperCase()) ||
          deposit.created_at.toUpperCase().includes(keyword.toUpperCase())
      );
      setDeposits(filtered);
    }
  }

  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <ResponsiveAppBar></ResponsiveAppBar>
      <div className={classes.div}>
        <div className={classes.content}>
          <Link className={classes.quit} to="/deposits">
            <ArrowBack fontSize="large" />
          </Link>
          <div>
            <Table style={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className={classes.title}>Saques conferidos</div>{" "}
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      onKeyUp={changeSearch}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <FontAwesomeIcon icon={faSearch} />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Buscar Depósitos"
                      variant="outlined"
                      style={{ backgroundColor: "#fff", minWidth: 200 }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TableCell>
                      <DesktopDatePicker
                        label="De:"
                        inputFormat="dd/MM/yyyy"
                        value={startDateValue}
                        onChange={handleChangeStartDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </TableCell>
                    <TableCell>
                      <DesktopDatePicker
                        label="Até:"
                        inputFormat="dd/MM/yyyy"
                        value={endDateValue}
                        onChange={handleChangeEndDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </TableCell>
                  </LocalizationProvider>
                </TableRow>
              </TableHead>
            </Table>
            <Table style={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Nick GG</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>Telefone</TableCell>
                  <TableCell>CPF</TableCell>
                  <TableCell>Banco</TableCell>
                  <TableCell>Agência</TableCell>
                  <TableCell>Conta</TableCell>
                  <TableCell>Comprovante</TableCell>
                  <TableCell>Data</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deposits.map((deposit) => (
                  <TableRow
                    hover
                    key={deposit.id}
                    style={{ backgroundColor: "#fff" }}
                  >
                    <TableCell> {deposit.id}</TableCell>
                    <TableCell> {deposit.payment_value}</TableCell>
                    <TableCell> {deposit.wallet.user.nick_name}</TableCell>
                    <TableCell>
                      {" "}
                      {deposit.wallet.user.full_name} <br />
                      <a
                        target="_blank"
                        href={`/userFinancials/${deposit.wallet.user.id}`}
                        rel="noreferrer"
                      >
                        Extrato
                      </a>
                      {!deposit.wallet.user.active ? (
                        <>
                          <br />
                          <span style={{ color: "red" }}>BLOQUEADO</span>
                        </>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell> {deposit.wallet.user.email}</TableCell>
                    <TableCell> {deposit.wallet.user.phone}</TableCell>
                    <TableCell> {deposit.wallet.user.cpf}</TableCell>
                    <TableCell> {deposit.metadata.bank}</TableCell>
                    <TableCell> {deposit.metadata.agency}</TableCell>
                    <TableCell> {deposit.metadata.account}</TableCell>
                    <TableCell>
                      {" "}
                      <ModalImage
                        className={classes.icon}
                        img={deposit.receipt_url}
                      />
                    </TableCell>
                    <TableCell> {deposit.created_at}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(AllDeposits);
