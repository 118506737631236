import React, { useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import GilroyRegular from "../../assets/Gilroy-Regular.ttf";
import background from "../../assets/backgroundControls.jpg";
import logo from "../../assets/whiteLogo.png";
import { Form } from "@unform/web";
import Input from "../Form/input";
import Select from "../Form/select";
import api from "../../services/api";
import { Link } from "react-router-dom";
import File from "../Form/file";
import { ArrowBack } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
const styles = (theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100vw",
    backgroundRepeat: "repeat",
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  div: {
    width: "35%",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  content: {
    backgroundColor: "#e8e8e8",
    padding: 30,
    margin: "auto",
    backgroundSize: "cover",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    borderRadius: 30,
    zIndex: 5,
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  input: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    borderRadius: 25,
    border: "none",
    color: "#383838",
    backgroundColor: "#fff",
    padding: 16,
    width: "100%",
    marginTop: "1%",
    marginBottom: "4%",
    position: "relative",
    WebkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
  },
  btn: {
    color: "#fff",
    borderWidth: 0,
    borderRadius: 40,
    fontSize: 16,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    backgroundImage: "linear-gradient(180deg,#e6007e 0%,#e94834 100%)",
    padding: "0.8em 1em",
    lineHeight: "1.7em",
    width: "100%",
    fontWeight: 600,
    margin: "5% 0",
  },
  "@font-face": {
    fontFamily: "Gilroy-regular",
    src: `url(${GilroyRegular})`,
  },
  logo: {
    width: "30%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  brand: {
    width: "15%",
    padding: "3%",
  },
  titleBrand: {
    padding: "1% 0",
    display: "flex",
    alignItems: "center",
    marginLeft: "-3%",
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    padding: "2% 0",
    textAlign: "center",
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
  },
  label: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 16,
    paddingLeft: "2%",
  },
  forgotDiv: {
    width: "100%",
    textAlign: "right",
  },
  forgot: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    textDecoration: "none",
  },
  errorDiv: {
    width: "100%",
    textAlign: "right",
    margin: 0,
    position: "absolute",
  },
  error: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#D84141",
    fontSize: 14,
    textDecoration: "none",
    margin: 0,
    fontWeight: 600,
  },
  singIn: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
  },
  singInLink: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: 600,
  },
  iconPassword: {
    color: "#383838",
    right: 21,
    top: 8,
    height: 44,
    fontSize: 20,
    position: "absolute",
  },
  quit: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#e6007e",
    width: "fit-content",
    textAlign: "left",
    textDecoration: "none",
    cursor: "pointer",
  },
  divSelect: {
    position: "relative",
    "&:after": {
      content: "'\\f078'",
      font: "normal normal normal 17px/1 FontAwesome",
      color: "#383838",
      right: 21,
      top: 4,
      height: 34,
      padding: "15px 0px 0px 8px",
      position: "absolute",
      pointerEvents: "none",
    },
  },
  iconChange: {
    cursor: "pointer",
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
      transform: "rotate(90deg)",
    },
  },
  divHalf: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  divTotal: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
});

function CreateMatches(props) {
  const { classes } = props;
  const formRef = useRef(null);
  const [error, setError] = React.useState(false);
  const [platforms, setPlatforms] = React.useState([]);
  const [games, setGames] = React.useState([]);
  const [gameModes, setGameModes] = React.useState([]);
  const [platformId, setPlatformId] = React.useState(0);
  const [gameId, setGameId] = React.useState(0);
  const [defineAward, setDefineAward] = React.useState(true);
  const [tax, setTax] = React.useState("");
  const [disabled, setDisabled] = React.useState(false);
  useEffect(() => {
    async function setPlatformsGamesValues() {
      let platforms = await api.get(`/platforms`);
      setPlatforms(platforms.data);
      let games = await api.get(`/games`);
      setGames(games.data);
    }
    setPlatformsGamesValues();
  }, []);
  useEffect(() => {
    async function setGameModesValues() {
      if (gameId && platformId) {
        let response = await api.get(
          `/games/listGameModesByGamePlatform?gameId=` +
            gameId +
            `&platformId=` +
            platformId
        );
        setGameModes(response.data);
      }
    }
    setGameModesValues();
  }, [platformId, gameId]);
  async function awardValueHandleChange() {
    let ref = formRef.current.getData();

    const totalAward = 2 * ref.ticket_value * (ref.awardPorcent / 100);
    formRef.current.setFieldValue("award_value", totalAward);
  }
  async function awardPorcentHandleChange() {
    let ref = formRef.current.getData();
    var totalPorcent;
    if (parseInt(ref.ticket_value) > 0) {
      totalPorcent = (ref.award_value / (2 * ref.ticket_value)) * 100;
    } else {
      totalPorcent = 100;
    }

    formRef.current.setFieldValue("awardPorcent", totalPorcent);
    setTax(100 - totalPorcent);
  }

  function platformHandleChange(e) {
    setPlatformId(e.target.value);
  }

  function gameHandleChange(e) {
    setGameId(e.target.value);
  }

  function awardHadleChange() {
    setDefineAward(!defineAward);
  }
  async function handleSubmit(data) {
    try {
      setDisabled(true);
      let formData = new FormData();
      formData.append("cover_image", data.cover_image);
      formData.append("ticket_value", parseFloat(data.ticket_value));
      formData.append("award_value", parseFloat(data.award_value));
      formData.append("game_mode_id", data.game_mode_id);
      await api.post(`/games/rooms`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      });
      window.location.assign("/matches");
    } catch (err) {
      setError(true);
      setDisabled(false);
    }
  }

  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <ResponsiveAppBar></ResponsiveAppBar>
      <div className={classes.div}>
        <div className={classes.content}>
          <Link className={classes.quit} to="/matches">
            <ArrowBack fontSize="large" />
          </Link>
          <div className={classes.title}>Criar Partida Rápida</div>
          <Form
            style={{ position: "relative" }}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            {error ? (
              <div className={classes.errorDiv}>
                <p className={classes.error}>Erro ao cadastrar partida.</p>
              </div>
            ) : (
              ""
            )}

            <label className={classes.label}>Console</label>
            <div className={classes.divSelect}>
              <Select
                required
                defaultValue=""
                name={"platformId"}
                onChange={platformHandleChange}
                style={{ paddingRight: 50 }}
                className={classes.input}
              >
                <option value="" disabled>
                  Selecione o console
                </option>
                {platforms.map((platform) => {
                  return (
                    <option key={platform.id} value={platform.id}>
                      {platform.name}
                    </option>
                  );
                })}
              </Select>
            </div>
            <label className={classes.label}>Jogo</label>
            <div className={classes.divSelect}>
              <Select
                required
                defaultValue=""
                name={"gameId"}
                onChange={gameHandleChange}
                style={{ paddingRight: 50 }}
                className={classes.input}
              >
                <option value="" disabled>
                  Selecione o jogo
                </option>
                {games.map((game) => {
                  return (
                    <option key={game.id} value={game.id}>
                      {game.name}
                    </option>
                  );
                })}
              </Select>
            </div>
            <label className={classes.label}>Modo de Jogo</label>
            <div className={classes.divSelect}>
              <Select
                required
                defaultValue=""
                name={"game_mode_id"}
                style={{ paddingRight: 50 }}
                className={classes.input}
              >
                <option value="" disabled>
                  Selecione o modo de jogo
                </option>
                {gameModes.map((gameMode) => {
                  return (
                    <option key={gameMode.id} value={gameMode.id}>
                      {gameMode.name}
                    </option>
                  );
                })}
              </Select>
            </div>
            <label className={classes.label}>Valor de participação</label>
            <Input
              required
              name="ticket_value"
              type="number"
              min="0.00"
              step="0.01"
              placeholder="Valor de participação da partida"
              className={classes.input}
              onChange={awardValueHandleChange}
            />
            <div className={classes.divTotal}>
              <div className={classes.divHalf}>
                <label className={classes.label}>Porcetagem da Premiação</label>
                <Input
                  required
                  name="awardPorcent"
                  disabled={!defineAward}
                  style={!defineAward ? { backgroundColor: "#dddddd" } : {}}
                  type="number"
                  min="0.00"
                  step="0.01"
                  placeholder="Porcentagem da Premiação"
                  className={classes.input}
                  onChange={(e) => {
                    setTax(100 - e.target.value);
                    awardValueHandleChange();
                  }}
                />
              </div>

              <FontAwesomeIcon
                icon={faExchangeAlt}
                className={classes.iconChange}
                onClick={awardHadleChange}
              ></FontAwesomeIcon>
              <div className={classes.divHalf}>
                <label className={classes.label}>Valor do prêmio</label>
                <Input
                  required
                  disabled={defineAward}
                  style={defineAward ? { backgroundColor: "#dddddd" } : {}}
                  onChange={awardPorcentHandleChange}
                  name="award_value"
                  type="number"
                  min="0.00"
                  step="0.01"
                  placeholder="Valor do prêmio da partida"
                  className={classes.input}
                />
              </div>
            </div>
            <div className={classes.divHalf}>
              <label className={classes.label}>Taxa GoodGain</label>
              <input
                style={{ backgroundColor: "#dddddd" }}
                disabled
                name="tax"
                className={classes.input}
                placeholder="Taxa GoodGain"
                value={tax}
              ></input>
            </div>
            <div>
              <File required name="cover_image" className={classes.inputFile} />
            </div>
            <button
              className={
                disabled ? classes.btn + " " + classes.disabled : classes.btn
              }
              type="submit"
            >
              Criar
            </button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(CreateMatches);
