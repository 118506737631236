import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import GilroyRegular from "../../assets/Gilroy-Regular.ttf";
import background from "../../assets/backgroundControls.jpg";
import logo from "../../assets/whiteLogo.png";
import api from "../../services/api";
import marked from "../marked";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardHeader,
  TextField,
  InputAdornment,
  SvgIcon,
  Button,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import ModalDelete from "../components/ModalDelete";
import ModalImage from "../components/ModalImage";
import ModalText from "../components/ModalText";
import { ArrowBack } from "@material-ui/icons";

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100vw",
    backgroundRepeat: "repeat",
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  div: {
    maxWidth: "100vw",
    backgroundColor: "#e8e8e8",
    borderRadius: 30,
    overflowX: "auto",
  },
  content: {
    zIndex: 5,
    display: "flex",
    paddingTop: 30,
    paddingBottom: 30,
    flexDirection: "column",
  },
  input: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    borderRadius: 25,
    border: "none",
    color: "#383838",
    backgroundColor: "#e8e8e8",
    padding: 16,
    width: "100%",
    marginTop: "1%",
    marginBottom: "4%",
    position: "relative",
  },
  btn: {
    color: "#fff",
    borderWidth: 0,
    borderRadius: 40,
    fontSize: 16,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    backgroundImage: "linear-gradient(180deg,#e6007e 0%,#e94834 100%)",
    padding: "0.8em 1em",
    lineHeight: "1.7em",
    width: "100%",
    fontWeight: 600,
    margin: "5% 0",
  },
  "@font-face": {
    fontFamily: "Gilroy-regular",
    src: `url(${GilroyRegular})`,
  },
  logo: {
    width: "30%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  brand: {
    width: "15%",
    padding: "3%",
  },
  titleBrand: {
    padding: "1% 0",
    display: "flex",
    alignItems: "center",
    marginLeft: "-3%",
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    padding: "2% 1%",
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
    width: "100%",
  },
  label: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 16,
    paddingLeft: "2%",
  },
  forgotDiv: {
    width: "100%",
    textAlign: "right",
  },
  forgot: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    textDecoration: "none",
  },
  errorDiv: {
    width: "100%",
    textAlign: "right",
    margin: 0,
    position: "absolute",
  },
  error: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#D84141",
    fontSize: 14,
    textDecoration: "none",
    margin: 0,
    fontWeight: 600,
  },
  singIn: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
  },
  singInLink: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: 600,
  },
  iconPassword: {
    color: "#383838",
    right: 21,
    top: 8,
    height: 44,
    fontSize: 20,
    position: "absolute",
  },
  quit: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#e6007e",
    width: "fit-content",
    textAlign: "left",
    textDecoration: "none",
    cursor: "pointer",
    paddingLeft: 16,
    paddingRight: 16,
  },
  createLink: {
    padding: "2% 0",
    textDecoration: "none",
    display: "flex",
    justifyContent: "flex-end",
    width: "50%",
  },
  icon: {
    fontSize: 30,
  },
});

function Awards(props) {
  const { classes } = props;
  const [deleted, setDeleted] = React.useState(0);
  const [awards, setAwards] = React.useState([]);
  const [fixedAwards, setFixedAwards] = React.useState([]);

  useEffect(() => {
    function convertTime(e) {
      var d = new Date(e);
      var h = addZero(d.getHours());
      var m = addZero(d.getMinutes());
      return h + "h" + m + "m";
    }
    async function setValues() {
      var response = await api.get(`/api/monthlyAwards/`);
      for (let i = 0; i < response.data.data.length; i++) {
        response.data.data[i].timeAwardStart = convertTime(
          date(response.data.data[i].dateAwardStart)
        );
        response.data.data[i].dateAwardStart = date(
          response.data.data[i].dateAwardStart
        ).toLocaleDateString("pt-br");
        response.data.data[i].timeAwardExpiration = convertTime(
          date(response.data.data[i].dateAwardExpiration)
        );
        response.data.data[i].dateAwardExpiration = date(
          response.data.data[i].dateAwardExpiration
        ).toLocaleDateString("pt-br");
      }
      setAwards(response.data.data);
      setFixedAwards(response.data.data);
    }
    setValues();
  }, [deleted]);

  async function deleteAward(id) {
    await api.delete(`/api/monthlyAwards/deleteMothlyAwards/` + id);
    setDeleted(deleted + 1);
  }
  function date(d) {
    let offset = new Date().getTimezoneOffset();
    let date = new Date(new Date(d).getTime() - offset);
    return date;
  }

  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
  function changeSearch(e) {
    if (e.key === "Enter") {
      let keyword = e.target.value;
      let filtered = fixedAwards.filter((entry) =>
        Object.values(entry).some(
          (val) =>
            typeof val === "string" &&
            val.toUpperCase().includes(keyword.toUpperCase())
        )
      );
      setAwards(filtered);
    }
  }

  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <div className={classes.div}>
        <div className={classes.content}>
          <Link className={classes.quit} to="/panel">
            <ArrowBack fontSize="large" />
          </Link>
          <div>
            <Table style={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className={classes.title}>Prêmios mensais</div>{" "}
                  </TableCell>
                  <TableCell
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Link
                      style={{ width: "100%" }}
                      className={classes.createLink}
                      to="/awards/create"
                    >
                      <Button
                        size="large"
                        style={{
                          width: "100%",
                          backgroundColor: "#17882c",
                          color: "#fff",
                        }}
                        variant="contained"
                      >
                        <div>Cadastrar Prêmio </div>
                      </Button>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      onKeyUp={changeSearch}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <FontAwesomeIcon icon={faSearch} />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Buscar Prêmio"
                      variant="outlined"
                      style={{ backgroundColor: "#fff", minWidth: 200 }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Table style={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell>Título</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Imagem</TableCell>
                  <TableCell>Data de início</TableCell>
                  <TableCell>Hora de início</TableCell>

                  <TableCell>Data de fim</TableCell>
                  <TableCell>Hora de fim</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {awards.map((award) => (
                  <TableRow
                    hover
                    key={award.id}
                    style={{ backgroundColor: "#fff" }}
                  >
                    <TableCell> {award.title}</TableCell>
                    <TableCell>
                      {" "}
                      <ModalText
                        title="Descrição"
                        text={marked(award.description)}
                      />
                    </TableCell>
                    <TableCell>
                      {" "}
                      <ModalImage
                        className={classes.icon}
                        img={
                          "https://good-gain-api.herokuapp.com/static/files/recive-deposit-vouchers/" +
                          award.imgHashMonthlyAwards
                        }
                      />
                    </TableCell>
                    <TableCell> {award.dateAwardStart}</TableCell>
                    <TableCell> {award.timeAwardStart}</TableCell>
                    <TableCell> {award.dateAwardExpiration}</TableCell>
                    <TableCell> {award.timeAwardExpiration}</TableCell>
                    <TableCell>
                      <ModalDelete
                        style={{ backgroundColor: "#8B0000", color: "#fff" }}
                      >
                        <CardHeader
                          subheader="Tem certeza que deseja excluir este prêmio?"
                          title="Excluir prêmio"
                        />
                        <Button
                          variant="contained"
                          style={{
                            margin: 10,
                            backgroundColor: "#8B0000",
                            color: "#fff",
                          }}
                          onClick={() => deleteAward(award.id)}
                        >
                          Excluir
                        </Button>
                      </ModalDelete>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Awards);
