import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import GilroyRegular from "../../assets/Gilroy-Regular.ttf";
import background from "../../assets/backgroundControls.jpg";
import logo from "../../assets/whiteLogo.png";
import api from "../../services/api";
import moment from "moment";
import { Divider, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import { useQuery } from "../../hooks";
const styles = (theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100vw",
    backgroundRepeat: "repeat",
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  div: {
    width: "35%",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  content: {
    backgroundColor: "#e8e8e8",
    padding: 30,
    margin: "auto",
    backgroundSize: "cover",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    borderRadius: 30,
    zIndex: 5,
    display: "flex",
    flexDirection: "column",
  },
  input: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    borderRadius: 25,
    border: "none",
    color: "#383838",
    backgroundColor: "#fff",
    padding: 16,
    width: "100%",
    marginTop: "1%",
    marginBottom: "4%",
    position: "relative",
  },
  btn: {
    color: "#fff",
    borderWidth: 0,
    borderRadius: 40,
    fontSize: 16,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    backgroundImage: "linear-gradient(180deg,#e6007e 0%,#e94834 100%)",
    padding: "0.8em 1em",
    lineHeight: "1.7em",
    width: "100%",
    fontWeight: 600,
    margin: "5% 0",
  },
  "@font-face": {
    fontFamily: "Gilroy-regular",
    src: `url(${GilroyRegular})`,
  },
  logo: {
    width: "30%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  brand: {
    width: "15%",
    padding: "3%",
  },
  titleBrand: {
    padding: "1% 0",
    display: "flex",
    alignItems: "center",
    marginLeft: "-3%",
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    padding: "2% 0",
    textAlign: "center",
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
  },
  label: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 16,
    paddingLeft: "2%",
  },
  forgotDiv: {
    width: "100%",
    textAlign: "right",
  },
  forgot: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    textDecoration: "none",
  },
  errorDiv: {
    width: "100%",
    textAlign: "right",
    margin: 0,
    position: "absolute",
  },
  error: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#D84141",
    fontSize: 14,
    textDecoration: "none",
    margin: 0,
    fontWeight: 600,
  },
  singIn: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
  },
  singInLink: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: 600,
  },
  iconPassword: {
    color: "#383838",
    right: 21,
    top: 8,
    height: 44,
    fontSize: 20,
    position: "absolute",
  },
  quit: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#e6007e",
    width: "fit-content",
    textAlign: "left",
    textDecoration: "none",
    cursor: "pointer",
  },
  createLink: {
    padding: "2% 0",
    textDecoration: "none",
    display: "flex",
    justifyContent: "flex-end",
    width: "50%",
  },
});

function Tournaments(props) {
  const { classes } = props;
  const [tournaments, setTournaments] = React.useState([]);
  const query = useQuery();
  const game_id = query.get("game_id");

  useEffect(() => {
    async function setTournamentValue() {
      let response = await api.get(
        `/tournaments/${game_id ? `?game_id=${game_id}` : ""}`
      );

      for (let i = 0; i < response.data.length; i++) {
        response.data[i].ticket_value = response.data[
          i
        ].ticket_value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });

        response.data[i].max_award_value = response.data[
          i
        ].max_award_value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });

        if (response.data[i].award_value) {
          response.data[i].award_value = response.data[
            i
          ].award_value.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        }

        if (response.data[i].second_award_value) {
          response.data[i].second_award_value = response.data[
            i
          ].second_award_value.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        }

        if (response.data[i].second_max_award_value) {
          response.data[i].second_max_award_value = response.data[
            i
          ].second_max_award_value.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        }

        response.data[i].start_time = moment(response.data[i].start_date)
          .utcOffset("-0300")
          .format("HH:mm");

        response.data[i].start_date = moment(response.data[i].start_date)
          .utcOffset("-0300")
          .format("yyyy-MM-DD");
      }

      let tournaments = response.data;

      tournaments.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.

        return new Date(b.start_date) - new Date(a.start_date);
      });

      tournaments.map((a) => {
        return (a.start_date = moment(a.start_date).format("DD/MM/YYYY"));
      });

      setTournaments(tournaments);
    }

    setTournamentValue();
  }, [game_id]);

  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <ResponsiveAppBar></ResponsiveAppBar>
      <div className={classes.div}>
        <div className={classes.content}>
          <Link className={classes.quit} to="/selectGames/tournaments">
            <ArrowBack fontSize="large" />
          </Link>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className={classes.title}>Torneios</div>
            <Link
              className={classes.createLink}
              to={
                "/tournaments/create" + (game_id ? `?game_id=${game_id}` : "")
              }
            >
              <Button
                style={{
                  margin: "2%",
                  backgroundColor: "#17882c",
                  color: "#fff",
                }}
                variant="contained"
              >
                Criar Torneio{" "}
              </Button>
            </Link>
          </div>
          <Divider />
          {tournaments.map((tournament) => (
            <div
              style={{ display: "flex", flexDirection: "column" }}
              key={tournament.id}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "2% 0",
                }}
              >
                <b className={classes.label}>{tournament.title}</b>
                {tournament.winner ? (
                  <b className={classes.label} style={{ color: "red" }}>
                    FINALIZADO
                  </b>
                ) : tournament.started ? (
                  <b className={classes.label} style={{ color: "green" }}>
                    INICIADO
                  </b>
                ) : (
                  ""
                )}
                <label className={classes.label}>ID: {tournament.id}</label>
                <label className={classes.label}>
                  Total de jogadores: {tournament.total_players}
                </label>
                <label className={classes.label}>
                  Valor do ticket: {tournament.ticket_value}
                </label>
                <label className={classes.label}>
                  Valor máximo do primeiro prêmio: {tournament.max_award_value}
                </label>
                <label className={classes.label}>
                  Valor máximo do segundo prêmio:{" "}
                  {tournament.second_max_award_value
                    ? tournament.second_max_award_value
                    : "-"}
                </label>
                <label className={classes.label}>
                  Valor do primeiro prêmio:{" "}
                  {tournament.award_value ? tournament.award_value : "-"}
                </label>
                <label className={classes.label}>
                  Valor do segundo prêmio:{" "}
                  {tournament.second_award_value
                    ? tournament.second_award_value
                    : "-"}
                </label>
                <label className={classes.label}>
                  Início: {tournament.start_date} {tournament.start_time}
                </label>
                <label className={classes.label}>
                  Console: {tournament.gameMode.gameWithPlatform.platform.name}
                </label>
                <label className={classes.label}>
                  Jogo: {tournament.gameMode.gameWithPlatform.game.name}
                </label>
                <label className={classes.label}>
                  Modo de Jogo: {tournament.gameMode.name}
                </label>
                <div style={{ display: "flex" }}>
                  <Link
                    style={{ margin: "2%", textDecoration: "none" }}
                    to={
                      "/tournaments/manage/" +
                      tournament.id +
                      (game_id ? `?game_id=${game_id}` : "")
                    }
                  >
                    <Button
                      style={{ backgroundColor: "#2543CA", color: "#fff" }}
                      variant="contained"
                    >
                      Gerenciar
                    </Button>
                  </Link>
                  <Link
                    style={{ margin: "2%", textDecoration: "none" }}
                    to={
                      "/tournaments/edit/" +
                      tournament.id +
                      (game_id ? `?game_id=${game_id}` : "")
                    }
                  >
                    <Button
                      style={{ backgroundColor: "#17882c", color: "#fff" }}
                      variant="contained"
                    >
                      Editar
                    </Button>
                  </Link>
                </div>
              </div>

              <Divider />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Tournaments);
