import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

const styles = (theme) => ({
  paper: {
    padding: 16,
    backgroundColor: "#212121",
    flexGrow: 1,
    maxWidth: "100%",
  },
  title: {
    color: "#fff",
    fontSize: 30,
    fontWeight: 600,
    marginBottom: 10,
  },
  text: {
    wordWrap: "break-word",
    color: "#fff",
    fontSize: 12,
  },
  dataDiv: {
    padding: 14,
    display: "flex",
    justifyContent: "center",
    marginTop: 16,
    borderRadius: 20,
  },
  dataText: {
    color: "white",
    fontSize: 22,
    fontWeight: "bold",
  },
  infoIcon: {
    cursor: "pointer",
    backgroundColor: "#121212",
    width: 8,
    height: 8,
    padding: 1,
    borderRadius: 20,
    border: "1px solid #e6007e",
    marginLeft: 4,
  },
  subtitle: {
    color: "#fff",
    fontSize: 10,
    marginTop: 16,
  },
  loadingDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: 40,
    marginBottom: 40,
  },
});

function IndicatorComponent(props) {
  const { classes } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [helpText, setHelpText] = React.useState("");

  const handleClick = (event, newHelpText) => {
    setHelpText(
      event.currentTarget !== anchorEl || helpText === "" ? newHelpText : ""
    );
    setAnchorEl(event.currentTarget);
  };

  return (
    <Grid item lg={4} md={6} xs={12}>
      <Paper className={classes.paper}>
        <div className={classes.title}>{props.title}</div>
        {props.loading ? (
          <div className={classes.loadingDiv}>
            <CircularProgress color="secondary" size={60} />
          </div>
        ) : (
          <>
            <Grid container spacing={2}>
              {props.data
                ? props.data.map((data, index) => (
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                      key={index}
                      item
                      xl={4}
                      lg={6}
                      md={6}
                      xs={12}
                    >
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography className={classes.text}>
                          {data.description}
                          {data.helpText ? (
                            <FontAwesomeIcon
                              className={classes.infoIcon}
                              onClick={(e) => handleClick(e, data.helpText)}
                              color="#e6007e"
                              icon={faQuestion}
                            />
                          ) : null}
                        </Typography>
                      </div>
                      <div
                        style={{
                          backgroundColor: data.backgroundColor
                            ? data.backgroundColor
                            : "#41D886",
                        }}
                        className={classes.dataDiv}
                      >
                        <Typography className={classes.dataText}>
                          {data.value}
                        </Typography>
                      </div>
                    </Grid>
                  ))
                : null}
            </Grid>
            {props.showSubtitle ? (
              <div className={classes.subtitle}>
                (*) Estatística desconsidera período.
              </div>
            ) : null}
          </>
        )}
      </Paper>
      <Popper open={!!helpText} anchorEl={anchorEl} transition>
        <Box
          sx={{
            border: 1,
            p: 1,
            bgcolor: "background.paper",
            maxWidth: "20vw",
          }}
          onClick={() => setHelpText("")}
        >
          {helpText}
        </Box>
      </Popper>
    </Grid>
  );
}

export default withStyles(styles)(IndicatorComponent);
