import React from 'react';
import AllPayments from '../components/views/AllPayments';
import withRoot from '../components/withRoot';

function Index() {

  return (
    <React.Fragment>
      <AllPayments />
    </React.Fragment>
  );
}

export default withRoot(Index);
