import React, { useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import GilroyRegular from "../../assets/Gilroy-Regular.ttf";
import background from "../../assets/backgroundControls.jpg";
import logo from "../../assets/whiteLogo.png";
import api from "../../services/api";
import {
  Button,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ArrowBack } from "@material-ui/icons";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import ModalImage from "../components/ModalImage";
import ModalCreate from "../components/ModalCreate";
import { useParams } from "react-router-dom";
import moment from "moment";
import Input from "../Form/input";
import { Form } from "@unform/web";

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100vw",
    backgroundRepeat: "repeat",
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  div: {
    maxWidth: "100vw",
    backgroundColor: "#e8e8e8",
    borderRadius: 30,
    overflowX: "auto",
  },
  content: {
    zIndex: 5,
    display: "flex",
    paddingTop: 30,
    paddingBottom: 30,
    flexDirection: "column",
  },
  input: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    borderRadius: 25,
    border: "none",
    color: "#383838",
    backgroundColor: "#e8e8e8",
    padding: 16,
    width: "100%",
    marginTop: "1%",
    marginBottom: "4%",
    position: "relative",
  },
  btn: {
    color: "#fff",
    borderWidth: 0,
    borderRadius: 40,
    fontSize: 16,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    backgroundImage: "linear-gradient(180deg,#e6007e 0%,#e94834 100%)",
    padding: "0.8em 1em",
    lineHeight: "1.7em",
    width: "100%",
    fontWeight: 600,
    margin: "5% 0",
  },
  "@font-face": {
    fontFamily: "Gilroy-regular",
    src: `url(${GilroyRegular})`,
  },
  logo: {
    width: "30%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  brand: {
    width: "15%",
    padding: "3%",
  },
  titleBrand: {
    padding: "1% 0",
    display: "flex",
    alignItems: "center",
    marginLeft: "-3%",
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    padding: "2% 1%",
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
    width: "100%",
  },
  label: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 16,
    paddingLeft: "2%",
  },
  forgotDiv: {
    width: "100%",
    textAlign: "right",
  },
  forgot: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    textDecoration: "none",
  },
  errorDiv: {
    width: "100%",
    textAlign: "right",
    margin: 0,
    position: "absolute",
  },
  error: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#D84141",
    fontSize: 14,
    textDecoration: "none",
    margin: 0,
    fontWeight: 600,
  },
  singIn: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
  },
  singInLink: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: 600,
  },
  iconPassword: {
    color: "#383838",
    right: 21,
    top: 8,
    height: 44,
    fontSize: 20,
    position: "absolute",
  },
  quit: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#e6007e",
    width: "fit-content",
    textAlign: "left",
    textDecoration: "none",
    cursor: "pointer",
    paddingLeft: 16,
    paddingRight: 16,
  },
  createLink: {
    padding: "2% 0",
    textDecoration: "none",
    display: "flex",
    justifyContent: "flex-end",
    width: "50%",
  },
  icon: {
    fontSize: 30,
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
});

function Users(props) {
  const { classes } = props;
  var { user_id } = useParams();
  const [financials, setFinancials] = React.useState([]);
  const [user, setUser] = React.useState();
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [startDateValue, setStartDateValue] = React.useState(new Date());
  const [endDateValue, setEndDateValue] = React.useState(new Date());
  const [disabled, setDisabled] = React.useState(false);
  const formRef = useRef(null);
  const [deleted, setDeleted] = React.useState(0);
  const [error, setError] = React.useState("");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [bonus, setBonus] = React.useState(false);

  useEffect(() => {
    async function setValues() {
      if (startDate && endDate) {
        var { data } = await api.put(
          `/wallet/listTransactionsByUser/${user_id}`,
          {
            startDate,
            endDate,
          }
        );
        for (let i = 0; i < data.length; i++) {
          data[i].created_at = new Date(data[i].created_at).toLocaleDateString(
            "pt-br"
          );
          data[i].formattedPaymentValue = parseFloat(
            data[i].payment_value
          ).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        }
        setFinancials(data);
      }
    }
    setValues();
  }, [startDate, endDate, user_id, deleted]);
  useEffect(() => {
    async function setUserValues() {
      var user = await api.get(`/users/findById/${user_id}`);
      setUser(user.data);
    }
    if (user_id) {
      setUserValues();
    }
    let startTime = removeDays(new Date(), 6);
    let endTime = addDays(new Date(), 1);
    setStartDateValue(startTime);
    setEndDateValue(endTime);
    setStartDate(
      startTime.getFullYear() +
        "-" +
        ("0" + (startTime.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + startTime.getDate()).slice(-2)
    );
    setEndDate(
      endTime.getFullYear() +
        "-" +
        ("0" + (endTime.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + endTime.getDate()).slice(-2)
    );
  }, [user_id]);
  function addDays(date, days) {
    var newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }
  function removeDays(date, days) {
    var newDate = new Date(date);
    newDate.setDate(newDate.getDate() - days);
    return newDate;
  }
  function handleChangeStartDate(startTime) {
    setStartDateValue(startTime);
    setStartDate(
      startTime.getFullYear() +
        "-" +
        ("0" + (startTime.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + startTime.getDate()).slice(-2)
    );
  }
  function handleChangeEndDate(endTime) {
    setEndDateValue(endTime);
    setEndDate(
      endTime.getFullYear() +
        "-" +
        ("0" + (endTime.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + endTime.getDate()).slice(-2)
    );
  }

  function getOriginTypeName(origin_type) {
    if (origin_type === "match") {
      return "Partida Rápida";
    }
    if (origin_type === "tournament") {
      return "Torneio";
    }
    if (origin_type === "bank") {
      return "Banco";
    }

    return "-";
  }

  function createTransaction(data) {
    setDisabled(true);
    data.bonus = bonus;
    api
      .post(`/wallet/transactionFixAdmin/`, data)
      .then((response) => {
        setDisabled(false);
        setDeleted(deleted + 1);
        setError("");
        setModalOpen(false);
        setBonus(false);
      })
      .catch((e) => {
        setError(e.response.data.message);
        setDisabled(false);
      });
  }
  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <ResponsiveAppBar></ResponsiveAppBar>
      <div className={classes.div}>
        <div className={classes.content}>
          <Link className={classes.quit} to="/users">
            <ArrowBack fontSize="large" />
          </Link>
          <div>
            <Table style={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className={classes.title}>Transações Financeiras</div>{" "}
                  </TableCell>
                  <TableCell>
                    {user ? (
                      <>
                        {!user.active ? (
                          <>
                            <span style={{ color: "red" }}>BLOQUEADO</span>
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        {user.full_name}
                        <br />
                        Nick GG: {user.nick_name}
                        <br />
                        Inscrito em:{" "}
                        {moment(user.created_at).format("DD/MM/yyyy")}
                        <br />
                        Saldo:{" "}
                        {parseFloat(user.wallet.total_balance).toLocaleString(
                          "pt-br",
                          {
                            style: "currency",
                            currency: "BRL",
                          }
                        )}
                        <br />
                        Saldo sacável:{" "}
                        {parseFloat(
                          user.wallet.drawable_balance
                        ).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </>
                    ) : (
                      ""
                    )}
                    <br />
                    <ModalCreate
                      text="Adicionar transação"
                      open={modalOpen}
                      setOpen={setModalOpen}
                      style={{
                        width: "fit-content",
                        color: "white",
                        backgroundColor: "#17882c",
                      }}
                    >
                      <CardHeader
                        subheader="Adicione uma transação de debito ou crédito, sacavel ou não"
                        title="Adicionar transação"
                      />
                      <span style={{ color: "red" }}>{error}</span>
                      <Form
                        style={{ position: "relative" }}
                        ref={formRef}
                        onSubmit={createTransaction}
                      >
                        <Input
                          required
                          name="payment_value"
                          type="number"
                          step="0.01"
                          placeholder="Valor da transação"
                          className={classes.input}
                        />
                        <div style={{ display: "flex" }}>
                          <Input
                            name="bonus"
                            id="bonus"
                            type="checkbox"
                            onClick={() => {
                              setBonus(!bonus);
                            }}
                            defaultChecked={bonus}
                          />
                          <label htmlFor="bonus">Valor NÃO sacável?</label>
                        </div>
                        <Input
                          required
                          name="user_id"
                          type="hidden"
                          value={user_id}
                        />
                        <Button
                          variant="contained"
                          style={{
                            margin: 10,
                            backgroundColor: "#17882c",
                            color: "#fff",
                          }}
                          className={disabled ? classes.disabled : ""}
                          disabled={disabled}
                          type="submit"
                        >
                          Criar
                        </Button>
                      </Form>
                    </ModalCreate>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TableCell>
                      <DesktopDatePicker
                        label="De:"
                        inputFormat="dd/MM/yyyy"
                        value={startDateValue}
                        onChange={handleChangeStartDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </TableCell>
                    <TableCell>
                      <DesktopDatePicker
                        label="Até:"
                        inputFormat="dd/MM/yyyy"
                        value={endDateValue}
                        onChange={handleChangeEndDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </TableCell>
                  </LocalizationProvider>
                </TableRow>
              </TableHead>
            </Table>
            <Table style={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Origem</TableCell>
                  <TableCell>ID Origem</TableCell>
                  <TableCell>Jogo</TableCell>
                  <TableCell>Console</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Recibo</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {financials.map((financial) => (
                  <TableRow
                    hover
                    key={financial.id}
                    style={{ backgroundColor: "#fff" }}
                  >
                    <TableCell> {financial.id}</TableCell>
                    <TableCell>
                      {" "}
                      {getOriginTypeName(financial.origin_type)}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {financial.origin_id &&
                      (financial.origin_type === "match" ||
                        financial.origin_type === "tournament")
                        ? financial.origin_id
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {financial.game_mode_origin
                        ? financial.game_mode_origin.game.name
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {financial.game_mode_origin
                        ? financial.game_mode_origin.platform.name
                        : "-"}
                    </TableCell>
                    <TableCell> {financial.paymentType.description}</TableCell>
                    <TableCell>
                      {" "}
                      {financial.paymentStatus.description}
                    </TableCell>
                    <TableCell>
                      {financial.receipt_url ? (
                        <ModalImage
                          className={classes.icon}
                          img={financial.receipt_url}
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell> {financial.created_at}</TableCell>
                    <TableCell> {financial.formattedPaymentValue}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Users);
