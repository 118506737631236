import React from 'react';
import Panel from '../components/views/Panel';
import withRoot from '../components/withRoot';

function Index() {

  return (
    <React.Fragment>
      <Panel />
    </React.Fragment>
  );
}

export default withRoot(Index);
