import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";

const pages = [
  { id: "1", title: "Início", page: "panel" },
  { id: "2", title: "Torneios", page: "tournaments" },
  { id: "3", title: "Partidas", page: "wins" },
  { id: "4", title: "Financeiro", page: "financial" },
  { id: "5", title: "Depósitos", page: "payments" },
  { id: "6", title: "Saques", page: "deposits" },
  { id: "7", title: "Usuários", page: "users" },
];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  let navigate = useHistory();

  const HandleNavigation = (navigation) => {
    if (navigation.page !== "subMenu") {
      navigate.push(`/${navigation.page}`);
    }
  };

  return (
    <AppBar
      style={{
        marginBottom: "40px",
        backgroundColor: "#e8e8e8",
        color: "#000",
        width: "35%",
        borderRadius: "20px",
      }}
      position="static"
    >
      <Container maxWidth="sm">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.id}
                  onClick={() => {
                    HandleNavigation(page);
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu>

            <div style={{ marginTop: "13px" }} onClick={handleOpenNavMenu}>
              Menu
            </div>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <div style={{ marginLeft: "-25px", display: "flex" }}>
              {pages.map((page) => (
                <Button
                  style={{ marginRight: "-4px" }}
                  key={page.id}
                  onClick={() => {
                    HandleNavigation(page);
                  }}
                  sx={{ my: 2, color: "#000", display: "block" }}
                >
                  {page.title}
                </Button>
              ))}
            </div>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
