import React from 'react';
import Login from '../components/views/LoginPanel';
import withRoot from '../components/withRoot';

function Index() {

  return (
    <React.Fragment>
      <Login />
    </React.Fragment>
  );
}

export default withRoot(Index);
