import React, { useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import GilroyRegular from "../../assets/Gilroy-Regular.ttf";
import background from "../../assets/backgroundControls.jpg";
import logo from "../../assets/whiteLogo.png";
import { Form } from "@unform/web";
import Input from "../Form/input";
import api from "../../services/api";
import { Link } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
const styles = (theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100vw",
    backgroundRepeat: "repeat",
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  div: {
    width: "35%",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  content: {
    backgroundColor: "#e8e8e8",
    padding: 30,
    margin: "auto",
    backgroundSize: "cover",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    borderRadius: 30,
    zIndex: 5,
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  input: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    borderRadius: 25,
    border: "none",
    color: "#383838",
    backgroundColor: "#fff",
    padding: 16,
    width: "100%",
    marginTop: "1%",
    marginBottom: "4%",
    position: "relative",
    WebkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
  },
  inputFile: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    border: "none",
    color: "#383838",
    padding: 16,
    width: "100%",
    marginTop: "1%",
    marginBottom: "4%",
    position: "relative",
  },
  btn: {
    color: "#fff",
    borderWidth: 0,
    borderRadius: 40,
    fontSize: 16,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    backgroundImage: "linear-gradient(180deg,#e6007e 0%,#e94834 100%)",
    padding: "0.8em 1em",
    lineHeight: "1.7em",
    width: "100%",
    fontWeight: 600,
    margin: "5% 0",
  },
  "@font-face": {
    fontFamily: "Gilroy-regular",
    src: `url(${GilroyRegular})`,
  },
  logo: {
    width: "30%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  brand: {
    width: "15%",
    padding: "3%",
  },
  titleBrand: {
    padding: "1% 0",
    display: "flex",
    alignItems: "center",
    marginLeft: "-3%",
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    padding: "2% 0",
    textAlign: "center",
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
  },
  label: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 16,
    paddingLeft: "2%",
  },
  forgotDiv: {
    width: "100%",
    textAlign: "right",
  },
  forgot: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    textDecoration: "none",
  },
  errorDiv: {
    width: "100%",
    textAlign: "right",
    margin: 0,
    position: "absolute",
  },
  error: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#D84141",
    fontSize: 14,
    textDecoration: "none",
    margin: 0,
    fontWeight: 600,
  },
  singIn: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
  },
  singInLink: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: 600,
  },
  iconPassword: {
    color: "#383838",
    right: 21,
    top: 8,
    height: 44,
    fontSize: 20,
    position: "absolute",
  },
  quit: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#e6007e",
    width: "fit-content",
    textAlign: "left",
    textDecoration: "none",
    cursor: "pointer",
  },
  divSelect: {
    position: "relative",
    "&:after": {
      content: "'\\f078'",
      font: "normal normal normal 17px/1 FontAwesome",
      color: "#383838",
      right: 21,
      top: 4,
      height: 34,
      padding: "15px 0px 0px 8px",
      position: "absolute",
      pointerEvents: "none",
    },
  },
  iconChange: {
    cursor: "pointer",
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
      transform: "rotate(90deg)",
    },
  },
  divHalf: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  divTotal: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
});

function Consoles(props) {
  const { classes } = props;
  const formRef = useRef(null);
  const [error, setError] = React.useState(false);

  async function handleSubmit(data) {
    try {
      data.adminUserId = JSON.parse(localStorage.getItem("adminData")).adminId;
      await api.post(`/api/consoleGames/console`, data);
      window.location.assign("/consoles");
    } catch (err) {
      setError(true);
    }
  }

  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <div className={classes.div}>
        <div className={classes.content}>
          <Link className={classes.quit} to="/consoles">
            <ArrowBack fontSize="large" />
          </Link>
          <div className={classes.title}>Cadastrar Prêmio</div>
          <Form
            style={{ position: "relative" }}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            {error ? (
              <div className={classes.errorDiv}>
                <p className={classes.error}>Erro ao cadastrar console.</p>
              </div>
            ) : (
              ""
            )}
            <label className={classes.label}>Descrição</label>
            <Input
              required
              name="description"
              style={{ marginBottom: 5 }}
              type="text"
              className={classes.input}
            />
            <label className={classes.label}>ID websocket </label>
            <Input
              required
              name="websocketDescription"
              style={{ marginBottom: 5 }}
              type="text"
              className={classes.input}
            />
            <button className={classes.btn} type="submit">
              Cadastrar
            </button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Consoles);
