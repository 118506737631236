import React from 'react';
import Tournaments from '../components/views/Tournaments';
import withRoot from '../components/withRoot';

function Index() {

  return (
    <React.Fragment>
      <Tournaments />
    </React.Fragment>
  );
}

export default withRoot(Index);
