const isAuthenticated = () => {
  if (localStorage.getItem("token") && localStorage.getItem("adminData")) {
    return true;
  }
  return false;
};
const isDashboardAuthorized = () => {
  if (localStorage.getItem("isDashboardAuthorized")) {
    return true;
  }
  return false;
};
export { isAuthenticated, isDashboardAuthorized };
