import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "30%",
    borderRadius: 30,
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
}));

export default function TransitionsModal(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    if (props.setOpen) {
      props.setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (props.setOpen) {
      props.setOpen(false);
    }
  };
  useEffect(() => {
    if (typeof props.open !== "undefined") {
      setOpen(props.open);
    }
  }, [props.open]);

  return (
    <>
      <Button style={props.style} onClick={handleOpen} variant="contained">
        {props.text}
      </Button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>{props.children}</div>
        </Fade>
      </Modal>
    </>
  );
}
