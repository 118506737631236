import React from "react";
import ManageTournaments from "../components/views/ManageTournaments";
import withRoot from "../components/withRoot";

function Index() {
  return (
    <React.Fragment>
      <ManageTournaments />
    </React.Fragment>
  );
}

export default withRoot(Index);
