import React from "react";
import { isAuthenticated, isDashboardAuthorized } from "./auth";
import LoginPanel from "./pages/LoginPanel";
import Panel from "./pages/Panel";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Tournaments from "./pages/Tournaments";
import CreateTournaments from "./pages/CreateTournaments";
import EditTournaments from "./pages/EditTournaments";
import ManageTournaments from "./pages/ManageTournaments";
import Matches from "./pages/Matches";
import CreateMatches from "./pages/CreateMatches";
import EditMatches from "./pages/EditMatches";
import Payments from "./pages/Payments";
import PendPayments from "./pages/PendPayments";
import AllPayments from "./pages/AllPayments";
import Wins from "./pages/Wins";
import PendWins from "./pages/PendWins";
import AllWins from "./pages/AllWins";
import Awards from "./pages/Awards";
import CreateAwards from "./pages/CreateAwards";
import Deposits from "./pages/Deposits";
import PendDeposits from "./pages/PendDeposits";
import AllDeposits from "./pages/AllDeposits";
import ConsoleGame from "./pages/ConsoleGame";
import Consoles from "./pages/Consoles";
import CreateConsole from "./pages/CreateConsole";
import EditConsole from "./pages/EditConsole";
import Financial from "./pages/Financial";
import UserFinancials from "./pages/UserFinancials";
import Users from "./pages/Users";
import Games from "./pages/Games";
import AllReferrals from "./components/views/AllReferrals";
import SelectGames from "./pages/SelectGames";
import ValidationDocuments from "./pages/ValidationDocuments";
import MatchFeedbacks from "./pages/MatchFeedbacks";
import DashboardAuth from "./pages/DashboardAuth";
import Dashboard from "./pages/Dashboard";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);
const NoPrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Redirect
          to={{ pathname: "/panel", state: { from: props.location } }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const NoDashboardRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() && isDashboardAuthorized() ? (
        <Redirect
          to={{ pathname: "/dashboard", state: { from: props.location } }}
        />
      ) : isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const DashboardRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() && isDashboardAuthorized() ? (
        <Component {...props} />
      ) : isAuthenticated() ? (
        <Redirect
          to={{ pathname: "/dahsboardAuth", state: { from: props.location } }}
        />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <NoPrivateRoute exact path="/" component={LoginPanel} />
      <NoDashboardRoute exact path="/dashboardAuth" component={DashboardAuth} />
      <DashboardRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/panel" component={Panel} />
      <PrivateRoute exact path="/tournaments" component={Tournaments} />
      <PrivateRoute
        exact
        path="/tournaments/create"
        component={CreateTournaments}
      />
      <PrivateRoute
        exact
        path="/tournaments/edit/:id"
        component={EditTournaments}
      />
      <PrivateRoute
        exact
        path="/tournaments/manage/:id"
        component={ManageTournaments}
      />
      <PrivateRoute exact path="/matches" component={Matches} />
      <PrivateRoute exact path="/matches/create" component={CreateMatches} />
      <PrivateRoute exact path="/matches/edit/:id" component={EditMatches} />
      <PrivateRoute exact path="/payments" component={Payments} />
      <PrivateRoute exact path="/pendpayments" component={PendPayments} />
      <PrivateRoute exact path="/allpayments" component={AllPayments} />
      <PrivateRoute exact path="/wins" component={Wins} />
      <PrivateRoute exact path="/pendwins" component={PendWins} />
      <PrivateRoute exact path="/allwins" component={AllWins} />
      <PrivateRoute exact path="/awards" component={Awards} />
      <PrivateRoute exact path="/awards/create" component={CreateAwards} />
      <PrivateRoute exact path="/deposits" component={Deposits} />
      <PrivateRoute exact path="/penddeposits" component={PendDeposits} />
      <PrivateRoute exact path="/alldeposits" component={AllDeposits} />
      <PrivateRoute exact path="/consolegame" component={ConsoleGame} />
      <PrivateRoute exact path="/users" component={Users} />
      <PrivateRoute exact path="/consoles" component={Consoles} />
      <PrivateRoute exact path="/consoles/create" component={CreateConsole} />
      <PrivateRoute exact path="/consoles/edit/:id" component={EditConsole} />
      <PrivateRoute exact path="/games" component={Games} />
      <PrivateRoute exact path="/selectGames/:next" component={SelectGames} />
      <PrivateRoute exact path="/financial" component={Financial} />
      <PrivateRoute exact path="/referrals" component={AllReferrals} />
      <PrivateRoute
        exact
        path="/validationDocuments"
        component={ValidationDocuments}
      />
      <PrivateRoute
        exact
        path="/userFinancials/:user_id"
        component={UserFinancials}
      />
      <PrivateRoute exact path="/matchFeedbacks" component={MatchFeedbacks} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
