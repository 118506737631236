import React from 'react';
import Payments from '../components/views/Payments';
import withRoot from '../components/withRoot';

function Index() {

  return (
    <React.Fragment>
      <Payments />
    </React.Fragment>
  );
}

export default withRoot(Index);
