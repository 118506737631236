import React from 'react';
import EditConsole from '../components/views/EditConsole';
import withRoot from '../components/withRoot';

function Index() {

  return (
    <React.Fragment>
      <EditConsole />
    </React.Fragment>
  );
}

export default withRoot(Index);
