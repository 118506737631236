import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import GilroyRegular from "../../assets/Gilroy-Regular.ttf";
import background from "../../assets/backgroundControls.jpg";
import logo from "../../assets/whiteLogo.png";
import api from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ModalText from "../components/ModalText";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  SvgIcon,
  TablePagination,
  CardHeader,
  Button,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import ModalImage from "../components/ModalImage";
import ModalConfirm from "../components/ModalConfirm";
import ModalDeny from "../components/ModalDeny";
import moment from "moment";

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100vw",
    backgroundRepeat: "repeat",
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  div: {
    maxWidth: "100vw",
    backgroundColor: "#e8e8e8",
    borderRadius: 30,
    overflowX: "auto",
  },
  content: {
    zIndex: 5,
    display: "flex",
    paddingTop: 30,
    paddingBottom: 30,
    flexDirection: "column",
  },
  btn: {
    color: "#fff",
    borderWidth: 0,
    borderRadius: 40,
    fontSize: 16,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    backgroundImage: "linear-gradient(180deg,#e6007e 0%,#e94834 100%)",
    padding: "0.8em 1em",
    lineHeight: "1.7em",
    width: "100%",
    fontWeight: 600,
    margin: "5% 0",
  },
  "@font-face": {
    fontFamily: "Gilroy-regular",
    src: `url(${GilroyRegular})`,
  },
  logo: {
    width: "30%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  brand: {
    width: "15%",
    padding: "3%",
  },
  titleBrand: {
    padding: "1% 0",
    display: "flex",
    alignItems: "center",
    marginLeft: "-3%",
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    padding: "2% 1%",
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
    width: "100%",
  },
  forgotDiv: {
    width: "100%",
    textAlign: "right",
  },
  forgot: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    textDecoration: "none",
  },
  errorDiv: {
    width: "100%",
    textAlign: "right",
    margin: 0,
    position: "absolute",
  },
  error: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#D84141",
    fontSize: 14,
    textDecoration: "none",
    margin: 0,
    fontWeight: 600,
  },
  singIn: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
  },
  singInLink: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: 600,
  },
  iconPassword: {
    color: "#383838",
    right: 21,
    top: 8,
    height: 44,
    fontSize: 20,
    position: "absolute",
  },
  quit: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#e6007e",
    width: "fit-content",
    textAlign: "left",
    textDecoration: "none",
    cursor: "pointer",
    paddingLeft: 16,
    paddingRight: 16,
  },
  createLink: {
    padding: "2% 0",
    textDecoration: "none",
    display: "flex",
    justifyContent: "flex-end",
    width: "50%",
  },
  icon: {
    fontSize: 30,
  },
  label: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 16,
    paddingLeft: "2%",
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
  },
  infos: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  tableCell: {
    fontSize: 12,
  },
  menu: {
    boxShadow: "0px 0px 8px 0px #707070ab",
  },
  menuList: {
    fontSize: 12,
  },
  menuDiv: {
    position: "absolute",
    backgroundColor: "white",
    zIndex: "999",
    left: -130,
  },
  note: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
    textDecoration: "underline",
    width: 80,
  },
  divSelect: {
    position: "relative",
    width: "50%",
    "&:after": {
      content: "'\\f078'",
      font: "normal normal normal 17px/1 FontAwesome",
      color: "#383838",
      right: 21,
      top: 4,
      height: 34,
      padding: "12px 0px 0px 8px",
      position: "absolute",
      pointerEvents: "none",
    },
  },
  input: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    borderRadius: 25,
    border: "none",
    color: "#383838",
    backgroundColor: "#fff",
    padding: 16,
    width: "100%",
    marginTop: "1%",
    marginBottom: "4%",
    position: "relative",
    WebkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
});

function ValidationDocuments(props) {
  const { classes } = props;
  const [documents, setDocuments] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [status, setStatus] = React.useState("");
  const [limit, setLimit] = React.useState(10);
  const [search, setSearch] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);
  const [deleted, setDeleted] = React.useState(0);

  useEffect(() => {
    async function setValues() {
      var response = await api.get(
        `/users/listValidationDocuments?page=${page}&limit=${limit}&search=${search}&status=${status}`
      );
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].created_at = moment(response.data[i].created_at)
          .utcOffset("-0300")
          .format("DD/MM/YYYY");
        response.data[i].user.created_at = moment(
          response.data[i].user.created_at
        )
          .utcOffset("-0300")
          .format("DD/MM/YYYY");
      }
      setDocuments(response.data);
    }
    if (page >= 0 && limit) {
      setValues();
    }
    setDisabled(false);
  }, [page, limit, search, status, deleted]);

  function clearPhone(phone) {
    return phone
      ? phone
          .replace("(", "")
          .replace(")", "")
          .replace("-", "")
          .replace(" ", "")
      : "";
  }
  function statusColor(status) {
    if (status === "waiting") {
      return "#edbb00";
    }
    if (status === "rejected") {
      return "red";
    }
    if (status === "approved") {
      return "green";
    }
    return "unset";
  }

  function statusText(status) {
    if (status === "waiting") {
      return "Aguardando";
    }
    if (status === "approved") {
      return "Aprovado";
    }
    if (status === "rejected") {
      return "Rejeitado";
    }
    return "Aguardando";
  }

  function statusHandleChange(e) {
    setStatus(e.target.value);
  }

  async function rejectDocument(id) {
    setDisabled(true);
    try {
      await api.put(`/users/updateDocumentStatus/` + id, {
        status: "rejected",
      });
    } catch (e) {
      setDisabled(false);
    }
    setDocuments([]);
    setDeleted(deleted + 1);
  }

  async function approveDocument(id) {
    setDisabled(true);
    try {
      await api.put(`/users/updateDocumentStatus/` + id, {
        status: "approved",
      });
    } catch (e) {
      setDisabled(false);
    }
    setDocuments([]);
    setDeleted(deleted + 1);
  }

  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <ResponsiveAppBar></ResponsiveAppBar>
      <div className={classes.div}>
        <div className={classes.content}>
          <Link className={classes.quit} to="/panel">
            <ArrowBack fontSize="large" />
          </Link>
          <div>
            <Table size="small" style={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.title}>Validação de documentos</div>{" "}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <TextField
                      fullWidth
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          setSearch(e.target.value);
                          setPage(0);
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <FontAwesomeIcon icon={faSearch} />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Buscar Documento (Nome, E-mail, CPF, Telefone, País ou Estado)"
                      variant="outlined"
                      style={{ backgroundColor: "#fff", minWidth: 200 }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCell}></TableCell>
                  <TableCell
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                    className={classes.tableCell}
                  >
                    <label
                      htmlFor="status"
                      style={{ marginRight: 10 }}
                      className={classes.label}
                    >
                      Status:
                    </label>
                    <div className={classes.divSelect}>
                      <select
                        defaultValue={status}
                        onChange={statusHandleChange}
                        className={classes.input}
                      >
                        <option value="">Todas</option>
                        <option value="rejected">
                          {statusText("rejected")}
                        </option>
                        <option value="approved">
                          {statusText("approved")}
                        </option>
                        <option value="waiting">{statusText("waiting")}</option>
                      </select>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Table style={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Bloqueado</TableCell>
                  <TableCell className={classes.tableCell}>
                    Observação
                  </TableCell>
                  <TableCell className={classes.tableCell}>Nome</TableCell>
                  <TableCell className={classes.tableCell}>E-mail</TableCell>
                  <TableCell className={classes.tableCell}>Telefone</TableCell>
                  <TableCell className={classes.tableCell}>CPF</TableCell>
                  <TableCell className={classes.tableCell}>
                    Nascimento
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Data criação
                  </TableCell>
                  <TableCell className={classes.tableCell}>Gênero</TableCell>
                  <TableCell className={classes.tableCell}>País</TableCell>
                  <TableCell className={classes.tableCell}>Estado</TableCell>
                  <TableCell className={classes.tableCell}>
                    Enviado em
                  </TableCell>
                  <TableCell className={classes.tableCell}>Status</TableCell>
                  <TableCell className={classes.tableCell}>Frente</TableCell>
                  <TableCell className={classes.tableCell}>Verso</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documents.map((document) => (
                  <TableRow
                    hover
                    key={document.user.id}
                    style={{ backgroundColor: "#fff" }}
                  >
                    <TableCell style={{}}>
                      {!document.user.active ? (
                        <div
                          style={{
                            backgroundColor: "red",
                            color: "#fff",
                            borderRadius: 10,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          SIM
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "green",
                            color: "#fff",
                            borderRadius: 10,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          NÃO
                        </div>
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {document.user.note ? (
                        <div className={classes.note}>
                          <ModalText
                            title={document.user.note}
                            text={document.user.note}
                          />
                        </div>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {document.user.full_name}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {document.user.email}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      <a
                        target="_blank"
                        href={
                          "https://api.whatsapp.com/send?phone=55" +
                          clearPhone(document.user.phone)
                        }
                        rel="noreferrer"
                      >
                        {document.user.phone}
                      </a>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {document.user.cpf}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {document.user.birth_date}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {document.user.created_at}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {document.user.genre}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {document.user.country}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {document.user.states}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {document.created_at}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <span
                        style={{
                          color: statusColor(document.status),
                        }}
                      >
                        {statusText(document.status)}
                      </span>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <ModalImage
                        className={classes.icon}
                        img={document.front_url}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <ModalImage
                        className={classes.icon}
                        img={document.back_url}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <ModalConfirm
                        style={{
                          backgroundColor: "#17882c",
                          color: "#fff",
                          padding: 5,
                          width: 34,
                          margin: "0 4px",
                        }}
                      >
                        <CardHeader
                          subheader="Tem certeza que deseja aprovar documento?"
                          title="Aprovar documento."
                        />

                        <Button
                          variant="contained"
                          style={{
                            margin: 10,
                            backgroundColor: "#17882c",
                            color: "#fff",
                          }}
                          className={disabled ? classes.disabled : ""}
                          disabled={disabled}
                          onClick={() => approveDocument(document.id)}
                          type="submit"
                        >
                          Aprovar
                        </Button>
                      </ModalConfirm>
                      <ModalDeny
                        style={{
                          backgroundColor: "#8B0000",
                          color: "#fff",
                          padding: 5,
                          width: 34,
                          margin: "0 4px",
                        }}
                      >
                        <CardHeader
                          subheader="Tem certeza que deseja rejeitar documento?"
                          title="Rejeitar documento."
                        />

                        <Button
                          variant="contained"
                          style={{
                            margin: 10,
                            backgroundColor: "#8B0000",
                            color: "#fff",
                          }}
                          className={disabled ? classes.disabled : ""}
                          disabled={disabled}
                          onClick={() => rejectDocument(document.id)}
                          type="submit"
                        >
                          Rejeitar
                        </Button>
                      </ModalDeny>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={-1}
              page={page}
              labelRowsPerPage="Linhas por página:"
              labelDisplayedRows={({ page }) => {
                return `Página: ${page + 1}`;
              }}
              onPageChange={(e, val) => setPage(val)}
              rowsPerPage={limit}
              onRowsPerPageChange={({ target }) => {
                setLimit(target.value);
                setPage(0);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(ValidationDocuments);
