import React from "react";
import DashboardAuth from "../components/views/DashboardAuth";
import withRoot from "../components/withRoot";

function Index() {
  return (
    <React.Fragment>
      <DashboardAuth />
    </React.Fragment>
  );
}

export default withRoot(Index);
