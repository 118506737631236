import React, { useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import GilroyRegular from "../../assets/Gilroy-Regular.ttf";
import background from "../../assets/backgroundControls.jpg";
import logo from "../../assets/whiteLogo.png";
import api from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ModalText from "../components/ModalText";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  SvgIcon,
  TablePagination,
  CardHeader,
  Button,
} from "@material-ui/core";
import { MenuItem, MenuList, IconButton, Divider } from "@mui/material";

import { Link } from "react-router-dom";
import { ArrowBack, MoreVert } from "@material-ui/icons";
import ModalComponent from "../components/Modal";
import Input from "../Form/input";
import { Form } from "@unform/web";
import TextArea from "../Form/textarea";

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100vw",
    backgroundRepeat: "repeat",
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  div: {
    maxWidth: "100vw",
    backgroundColor: "#e8e8e8",
    borderRadius: 30,
    overflowX: "auto",
  },
  content: {
    zIndex: 5,
    display: "flex",
    paddingTop: 30,
    paddingBottom: 30,
    flexDirection: "column",
  },
  input: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    borderRadius: 25,
    border: "none",
    color: "#383838",
    backgroundColor: "#e8e8e8",
    padding: 16,
    width: "100%",
    marginTop: "1%",
    marginBottom: "4%",
    position: "relative",
  },
  btn: {
    color: "#fff",
    borderWidth: 0,
    borderRadius: 40,
    fontSize: 16,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    backgroundImage: "linear-gradient(180deg,#e6007e 0%,#e94834 100%)",
    padding: "0.8em 1em",
    lineHeight: "1.7em",
    width: "100%",
    fontWeight: 600,
    margin: "5% 0",
  },
  "@font-face": {
    fontFamily: "Gilroy-regular",
    src: `url(${GilroyRegular})`,
  },
  logo: {
    width: "30%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  brand: {
    width: "15%",
    padding: "3%",
  },
  titleBrand: {
    padding: "1% 0",
    display: "flex",
    alignItems: "center",
    marginLeft: "-3%",
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    padding: "2% 1%",
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
    width: "100%",
  },
  forgotDiv: {
    width: "100%",
    textAlign: "right",
  },
  forgot: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    textDecoration: "none",
  },
  errorDiv: {
    width: "100%",
    textAlign: "right",
    margin: 0,
    position: "absolute",
  },
  error: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#D84141",
    fontSize: 14,
    textDecoration: "none",
    margin: 0,
    fontWeight: 600,
  },
  singIn: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
  },
  singInLink: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: 600,
  },
  iconPassword: {
    color: "#383838",
    right: 21,
    top: 8,
    height: 44,
    fontSize: 20,
    position: "absolute",
  },
  quit: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#e6007e",
    width: "fit-content",
    textAlign: "left",
    textDecoration: "none",
    cursor: "pointer",
    paddingLeft: 16,
    paddingRight: 16,
  },
  createLink: {
    padding: "2% 0",
    textDecoration: "none",
    display: "flex",
    justifyContent: "flex-end",
    width: "50%",
  },
  icon: {
    fontSize: 30,
  },
  label: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 16,
    paddingLeft: "2%",
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
  },
  infos: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  tableCell: {
    fontSize: 12,
  },
  menu: {
    boxShadow: "0px 0px 8px 0px #707070ab",
  },
  menuList: {
    fontSize: 12,
  },
  menuDiv: {
    position: "absolute",
    backgroundColor: "white",
    zIndex: "999",
    left: -130,
  },
  note: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
    textDecoration: "underline",
    width: 80,
  },
});

function Users(props) {
  const { classes } = props;
  const [players, setPlayers] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [search, setSearch] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [openId, setOpenId] = React.useState(null);
  const [openedBlockModalId, setOpenedBlockModalId] = React.useState(null);
  const [openedNoteModalId, setOpenedNoteModalId] = React.useState(null);
  const formRef = useRef(null);

  useEffect(() => {
    async function setValues() {
      var response = await api.get(
        `/users/list?page=${page}&limit=${limit}&search=${search}`
      );
      var count = await api.get(`/users/count`);
      setTotal(count.data);
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].created_at = new Date(
          response.data[i].created_at
        ).toLocaleDateString("pt-br");
      }
      setPlayers(response.data);
    }
    if (page >= 0 && limit) {
      setValues();
    }
  }, [page, limit, search]);

  function clearPhone(phone) {
    return phone
      ? phone
          .replace("(", "")
          .replace(")", "")
          .replace("-", "")
          .replace(" ", "")
      : "";
  }

  async function load() {
    var response = await api.get(
      `/users/list?page=${page}&limit=${limit}&search=${search}`
    );

    var count = await api.get(`/users/count`);

    setTotal(count.data);

    for (let i = 0; i < response.data.length; i++) {
      response.data[i].created_at = new Date(
        response.data[i].created_at
      ).toLocaleDateString("pt-br");
    }

    setPlayers(response.data);
  }

  async function updateUserActive(user) {
    try {
      await api.put(`/users/update/active/` + user.id, {
        active: !user.active,
      });
      load();
    } catch (e) {
      console.error(e);
    }
  }

  async function updateUserNote(data) {
    try {
      setOpenedNoteModalId(null);
      await api.put(`/users/update/note/` + data.id, {
        note: data.note,
      });
      load();
    } catch (e) {
      console.error(e);
    }
  }

  const handleClick = (id) => {
    setOpenId(id);
  };
  const handleClose = () => {
    setOpenId(null);
  };

  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <ResponsiveAppBar></ResponsiveAppBar>
      {players ? (
        <>
          {openedNoteModalId ? (
            <ModalComponent
              onClose={() => {
                setOpenedNoteModalId(null);
              }}
              open={Boolean(openedNoteModalId)}
            >
              <CardHeader
                subheader="Adicione uma observação ao usuário"
                title="Adicionar observação"
              />
              <Form
                style={{ position: "relative" }}
                ref={formRef}
                onSubmit={updateUserNote}
              >
                <TextArea
                  required
                  name="note"
                  defaultValue={
                    players.find((player) => player.id === openedNoteModalId)
                      .note
                  }
                  placeholder="Observação"
                  className={classes.input}
                />
                <Input
                  required
                  name="id"
                  type="hidden"
                  value={openedNoteModalId}
                />
                <Button
                  variant="contained"
                  style={{
                    margin: 10,
                    backgroundColor: "#17882c",
                    color: "#fff",
                  }}
                  type="submit"
                >
                  Adicionar
                </Button>
              </Form>
            </ModalComponent>
          ) : (
            ""
          )}
          <ModalComponent
            onClose={() => {
              setOpenedBlockModalId(null);
            }}
            open={Boolean(openedBlockModalId)}
          >
            <CardHeader
              subheader={"Tem certeza que quer bloquear este usuário?"}
              title="Bloquear usuário"
            />

            <Button
              variant="contained"
              style={{
                margin: 10,
                backgroundColor: "#8B0000",
                color: "#fff",
              }}
              onClick={() => {
                updateUserActive(
                  players.find((player) => player.id === openedBlockModalId)
                );
                setOpenedBlockModalId(null);
              }}
            >
              Bloquear
            </Button>
          </ModalComponent>
        </>
      ) : (
        ""
      )}
      <div className={classes.div}>
        <div className={classes.content}>
          <Link className={classes.quit} to="/panel">
            <ArrowBack fontSize="large" />
          </Link>
          <div>
            <Table size="small" style={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.title}>Usuários</div>{" "}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <TextField
                      fullWidth
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          setSearch(e.target.value);
                          setPage(0);
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <FontAwesomeIcon icon={faSearch} />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Buscar Usuário (Nome, E-mail, CPF, Telefone, País ou Estado)"
                      variant="outlined"
                      style={{ backgroundColor: "#fff", minWidth: 200 }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.title}>Total: {total}</div>
                  </TableCell>
                  <TableCell className={classes.tableCell}></TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Table style={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Bloqueado</TableCell>
                  <TableCell className={classes.tableCell}>
                    Observação
                  </TableCell>
                  <TableCell className={classes.tableCell}>Nome</TableCell>
                  <TableCell className={classes.tableCell}>E-mail</TableCell>
                  <TableCell className={classes.tableCell}>Telefone</TableCell>
                  <TableCell className={classes.tableCell}>CPF</TableCell>
                  <TableCell className={classes.tableCell}>
                    Nascimento
                  </TableCell>
                  <TableCell className={classes.tableCell}>Nick GG</TableCell>
                  <TableCell className={classes.tableCell}>Consoles</TableCell>
                  <TableCell className={classes.tableCell}>
                    Saldo total
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Saldo sacável
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Data criação
                  </TableCell>
                  <TableCell className={classes.tableCell}>Gênero</TableCell>
                  <TableCell className={classes.tableCell}>País</TableCell>
                  <TableCell className={classes.tableCell}>Estado</TableCell>
                  <TableCell className={classes.tableCell}>
                    Financeiro
                  </TableCell>
                  <TableCell className={classes.tableCell}>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {players.map((player) => (
                  <TableRow
                    hover
                    key={player.id}
                    style={{ backgroundColor: "#fff" }}
                  >
                    <TableCell style={{}}>
                      {!player.active ? (
                        <div
                          style={{
                            backgroundColor: "red",
                            color: "#fff",
                            borderRadius: 10,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          SIM
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "green",
                            color: "#fff",
                            borderRadius: 10,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          NÃO
                        </div>
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {player.note ? (
                        <div className={classes.note}>
                          <ModalText title={player.note} text={player.note} />
                        </div>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {player.full_name}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {player.email}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      <a
                        target="_blank"
                        href={
                          "https://api.whatsapp.com/send?phone=55" +
                          clearPhone(player.phone)
                        }
                        rel="noreferrer"
                      >
                        {player.phone}
                      </a>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {player.cpf}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {player.birth_date}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {player.nick_name}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <ModalText
                        style={{ width: "fit-content" }}
                        title="Consoles"
                        className={classes.infos}
                        text={
                          <div
                            style={{
                              width: "fit-content",
                              padding: 10,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {player.platforms.map((platform) => (
                              <label
                                key={platform.id}
                                className={classes.label}
                              >
                                <b>{platform.platform.name}: </b>
                                {platform.platform_identifier}
                              </label>
                            ))}
                          </div>
                        }
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {player.wallet.total_balance.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {player.wallet.drawable_balance.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {player.created_at}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {player.genre}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {player.country}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {player.states}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      <a
                        target="_blank"
                        href={`/userFinancials/${player.id}`}
                        rel="noreferrer"
                      >
                        Detalhe
                      </a>
                    </TableCell>
                    <TableCell
                      style={{ position: "relative" }}
                      className={classes.tableCell}
                    >
                      <IconButton
                        onClick={() => {
                          handleClick(player.id !== openId ? player.id : null);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                      <div
                        style={{
                          display: player.id === openId ? "block" : "none",
                        }}
                        className={classes.menuDiv}
                      >
                        <MenuList className={classes.menu} elevation={0}>
                          <MenuItem
                            className={classes.menuItem}
                            onClick={() => {
                              handleClose();
                              setOpenedNoteModalId(player.id);
                            }}
                          >
                            Adicionar observação
                          </MenuItem>
                          <Divider sx={{ my: 0.5 }} />
                          <MenuItem
                            className={classes.menuItem}
                            onClick={() => {
                              if (player.active) {
                                setOpenedBlockModalId(player.id);
                              } else {
                                updateUserActive(player);
                              }
                              handleClose();
                            }}
                          >
                            {!player.active
                              ? "Desbloquear usuário"
                              : "Bloquear usuário"}
                          </MenuItem>
                        </MenuList>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={-1}
              page={page}
              labelRowsPerPage="Linhas por página:"
              labelDisplayedRows={({ page }) => {
                return `Página: ${page + 1}`;
              }}
              onPageChange={(e, val) => setPage(val)}
              rowsPerPage={limit}
              onRowsPerPageChange={({ target }) => {
                setLimit(target.value);
                setPage(0);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Users);
