import React from 'react';
import Awards from '../components/views/Awards';
import withRoot from '../components/withRoot';

function Index() {

  return (
    <React.Fragment>
      <Awards />
    </React.Fragment>
  );
}

export default withRoot(Index);
