import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import GilroyRegular from "../../assets/Gilroy-Regular.ttf";
import background from "../../assets/backgroundControls.jpg";
import logo from "../../assets/whiteLogo.png";
import marcaLogo from "../../assets/marcaLogo.png";
import { Link } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import api from "../../services/api";
import { useParams } from "react-router-dom";
const styles = (theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100vw",
    backgroundRepeat: "repeat",
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  div: {
    width: "35%",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  content: {
    backgroundColor: "#e8e8e8",
    padding: 30,
    margin: "auto",
    marginBottom: 30,
    backgroundSize: "cover",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    borderRadius: 30,
    zIndex: 5,
    display: "flex",
    flexDirection: "column",
  },
  input: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    borderRadius: 25,
    border: "none",
    color: "#383838",
    backgroundColor: "#fff",
    padding: 16,
    width: "100%",
    marginTop: "2%",
    marginBottom: "2%",
  },
  btn: {
    borderWidth: 0,
    borderRadius: 20,
    padding: "0.8em 1em",
    width: "100%",
    margin: "2% 0",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    position: "relative",
    lineHeight: 1,
    backgroundColor: "#fff",
    cursor: "pointer",
  },
  btnText: {
    fontSize: 16,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
    fontWeight: 600,
    lineHeight: 1,
    width: "100%",
    textAlign: "center",
  },
  "@font-face": {
    fontFamily: "Gilroy-regular",
    src: `url(${GilroyRegular})`,
  },
  logo: {
    width: "30%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },

  title: {
    padding: "1% 0",
    fontSize: 30,
    fontWeight: 600,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
    textAlign: "center",
  },
  balance: {
    fontSize: 40,
    fontWeight: 600,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
    textAlign: "center",
  },
  subtitle: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 16,
    padding: "2% 0",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  subtitle2: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 15,
    padding: "2% 0",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  quit: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#e6007e",
    width: "fit-content",
    textAlign: "left",
    textDecoration: "none",
    cursor: "pointer",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  image: {
    borderRadius: 20,
    width: 100,
    height: 100,
    objectFit: "cover",
    objectPosition: "center",
  },
});

function SelectGames(props) {
  const { classes } = props;
  var { next } = useParams();

  const [games, setGames] = React.useState([]);

  useEffect(() => {
    async function setPlatformsGamesValues() {
      let games = await api.get(`/games`);
      setGames(games.data);
    }
    setPlatformsGamesValues();
  }, []);
  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <ResponsiveAppBar></ResponsiveAppBar>
      <div className={classes.div}>
        <div className={classes.content}>
          <Link
            className={classes.quit}
            to={next === "tournaments" ? "/panel" : "/wins"}
          >
            <ArrowBack fontSize="large" />
          </Link>
          <div className={classes.title}>Selecione o Jogo</div>
          <div className={classes.row}>
            <Link className={classes.btn} to={`/${next}`}>
              <img
                src={marcaLogo}
                alt="logo do jogo"
                className={classes.image}
              />
              <div className={classes.btnText}>Todos</div>
            </Link>
          </div>
          {games.map((game) => (
            <div key={game.id} className={classes.row}>
              <Link className={classes.btn} to={`/${next}?game_id=${game.id}`}>
                <img
                  src={game.cover_image_url}
                  alt="logo do jogo"
                  className={classes.image}
                />
                <div className={classes.btnText}>{game.name}</div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(SelectGames);
