import React from 'react';
import CreateConsole from '../components/views/CreateConsole';
import withRoot from '../components/withRoot';

function Index() {

  return (
    <React.Fragment>
      <CreateConsole />
    </React.Fragment>
  );
}

export default withRoot(Index);
