import React, { useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import GilroyRegular from "../../assets/Gilroy-Regular.ttf";
import background from "../../assets/backgroundControls.jpg";
import logo from "../../assets/whiteLogo.png";
import user from "../../assets/user.svg";
import { Form } from "@unform/web";
import Input from "../Form/input";
import Select from "../Form/select";
import api from "../../services/api";
import { CardHeader, Button } from "@material-ui/core";
import ModalConfirm from "../components/ModalConfirm";
import ModalReload from "../components/ModalReload";
import ModalCreate from "../components/ModalCreate";
import ModalText from "../components/ModalText";
import ModalImage from "../components/ModalImage";
import ModalIcon from "../components/ModalIcon";
import { Link, useParams } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import moment from "moment";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import { useQuery } from "../../hooks";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100vw",
    backgroundRepeat: "repeat",
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  div: {
    width: "35%",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  content: {
    backgroundColor: "#e8e8e8",
    padding: 30,
    margin: "auto",
    backgroundSize: "cover",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    borderRadius: 30,
    zIndex: 5,
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  input: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    borderRadius: 25,
    border: "none",
    color: "#383838",
    backgroundColor: "#fff",
    padding: 16,
    width: "100%",
    marginTop: "1%",
    marginBottom: "4%",
    position: "relative",
    WebkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
  },
  btn: {
    color: "#fff",
    borderWidth: 0,
    borderRadius: 40,
    fontSize: 16,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    backgroundImage: "linear-gradient(180deg,#e6007e 0%,#e94834 100%)",
    padding: "0.8em 1em",
    lineHeight: "1.7em",
    width: "100%",
    fontWeight: 600,
    margin: "5% 0",
  },
  "@font-face": {
    fontFamily: "Gilroy-regular",
    src: `url(${GilroyRegular})`,
  },
  logo: {
    width: "30%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  brand: {
    width: "15%",
    padding: "3%",
  },
  titleBrand: {
    padding: "1% 0",
    display: "flex",
    alignItems: "center",
    marginLeft: "-3%",
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    padding: "2% 0",
    textAlign: "center",
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
  },
  label: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 16,
    paddingLeft: "2%",
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
  },
  forgotDiv: {
    width: "100%",
    textAlign: "right",
  },
  forgot: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    textDecoration: "none",
  },
  errorDiv: {
    width: "100%",
    textAlign: "right",
    margin: 0,
    position: "absolute",
  },
  error: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#D84141",
    fontSize: 14,
    textDecoration: "none",
    margin: 0,
    fontWeight: 600,
  },
  singIn: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
  },
  singInLink: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: 600,
  },
  iconPassword: {
    color: "#383838",
    right: 21,
    top: 8,
    height: 44,
    fontSize: 20,
    position: "absolute",
  },
  quit: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#e6007e",
    width: "fit-content",
    textAlign: "left",
    textDecoration: "none",
    cursor: "pointer",
  },
  divSelect: {
    position: "relative",
    "&:after": {
      content: "'\\f078'",
      font: "normal normal normal 17px/1 FontAwesome",
      color: "#383838",
      right: 21,
      top: 4,
      height: 34,
      padding: "15px 0px 0px 8px",
      position: "absolute",
      pointerEvents: "none",
    },
  },
  iconChange: {
    cursor: "pointer",
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
      transform: "rotate(90deg)",
    },
  },
  divHalf: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  divTotal: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  infos: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  user: {
    width: 50,
    margin: 10,
    borderRadius: "50%",
    backgroundColor: "#e8e8e8",
  },
  match: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: 30,
    margin: "10px 0",
  },
  matchParticipant: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "2px solid #e8e8e8",
    padding: "10px",
  },
  matchParticipantContainer: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    fontSize: 30,
  },
  matchHeader: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 20px",
    borderBottom: "2px solid #e8e8e8",
  },
  matchFooter: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 20px",
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
});

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

function Tournaments(props) {
  const { classes } = props;
  const formRef = useRef(null);
  var { id } = useParams();
  const [update, setUpdate] = React.useState(0);
  const [tournament, setTournament] = React.useState({});
  const [error, setError] = React.useState("");
  const [bracket, setBracket] = React.useState("");
  const [disabled, setDisabled] = React.useState(false);
  const query = useQuery();
  const game_id = query.get("game_id");

  useEffect(() => {
    async function setTournamentValues(id) {
      let response = await api.get(`/tournaments/getById/` + id);
      response.data.ticket_value = response.data.ticket_value.toLocaleString(
        "pt-br",
        {
          style: "currency",
          currency: "BRL",
        }
      );

      response.data.max_award_value =
        response.data.max_award_value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      if (response.data.second_max_award_value) {
        response.data.second_max_award_value =
          response.data.second_max_award_value.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
      }

      if (response.data.award_value) {
        response.data.award_value = response.data.award_value.toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        );
      }

      if (response.data.second_award_value) {
        response.data.second_award_value =
          response.data.second_award_value.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
      }

      response.data.start_time = moment(response.data.start_date)
        .utcOffset("-0300")
        .format("HH:mm");
      response.data.start_date = moment(response.data.start_date)
        .utcOffset("-0300")
        .format("DD/MM/YYYY");
      setTournament(response.data);
      if (bracket === "") {
        if (response.data.total_players > 8) {
          setBracket("octavefinal");
        } else if (response.data.total_players > 4) {
          setBracket("quarterfinal");
        } else {
          setBracket("semifinal");
        }
      }
    }
    setTournamentValues(id);
    setDisabled(false);
    setError("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);
  useDidMountEffect(() => {
    setTimeout(function () {
      setUpdate(update + 1);
    }, 10000);
  }, [tournament]);

  function getStatus(status) {
    switch (status) {
      case "waiting":
        return {
          status: "Aguardando",
          color: "white",
          canConfirm: true,
        };
      case "waiting_ok":
        return {
          status: "Aguardando OK",
          color: "#D3DFFF",
          canConfirm: true,
        };
      case "in_progress":
        return {
          status: "Em progresso",
          color: "#A4BCFC",
          canConfirm: true,
        };
      case "w.o":
        return {
          status: "W.O",
          color: "#FFABAB",
          canConfirm: true,
        };
      case "waiting_confirm":
        return {
          status: "Aguardando confirmação",
          color: "#FFF5AB",
          canConfirm: true,
        };
      case "confirmed":
        return {
          status: "Confirmada",
          color: "#A6FF9F",
          canConfirm: false,
        };
      default:
        return {
          status: "Aguardando",
          color: "white",
          canConfirm: true,
        };
    }
  }

  async function handleSubmit(data) {
    setDisabled(true);
    try {
      await api.post(`/tournaments/confirmMatch/` + data.id, data);
    } catch (e) {
      setDisabled(false);
    }
    setUpdate(update + 1);
  }

  async function handleRemoveParticipant(user_id) {
    setDisabled(true);

    await api
      .post(`/tournaments/removeParticipant/` + id + "/" + user_id)
      .then((response) => {
        setTournament({});
        setUpdate(update + 1);
      })
      .catch((e) => {
        setError(e.response.data.message);
        setDisabled(false);
      });
  }

  async function handleReload(match_id) {
    setDisabled(true);
    let data = {
      status: "waiting_ok",
    };
    try {
      await api.put(`/tournaments/updateMatch/` + match_id, data);
    } catch (e) {
      setDisabled(false);
    }
    setUpdate(update + 1);
  }
  async function initTournament() {
    setDisabled(true);
    try {
      await api.post(`/tournaments/startTournament/` + id);
    } catch (e) {
      setDisabled(false);
    }
    setBracket("");
    setUpdate(update + 1);
  }
  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <ResponsiveAppBar></ResponsiveAppBar>
      <div className={classes.div}>
        <div className={classes.content}>
          <Link
            className={classes.quit}
            to={"/tournaments" + (game_id ? `?game_id=${game_id}` : "")}
          >
            <ArrowBack fontSize="large" />
          </Link>
          <div className={classes.title}>{tournament.title}</div>
          <div
            style={{ display: "flex", flexDirection: "column" }}
            key={tournament.id}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "2% 0",
              }}
            >
              {tournament.matches ? (
                tournament.winner ? (
                  <b className={classes.label} style={{ color: "red" }}>
                    FINALIZADO
                  </b>
                ) : tournament.started ? (
                  <b className={classes.label} style={{ color: "green" }}>
                    INICIADO
                  </b>
                ) : tournament.matches.length ? (
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <ModalCreate
                      text="Iniciar Torneio"
                      style={{
                        width: "fit-content",
                        color: "white",
                        backgroundColor: "#17882c",
                      }}
                    >
                      <CardHeader
                        subheader="Tem certeza que deseja iniciar o torneio? Caso não haja jogadores o suficiente o torneio pode ser cancelado"
                        title="Iniciar Torneio"
                      />
                      <Button
                        variant="contained"
                        style={{
                          margin: 10,
                          backgroundColor: "#17882c",
                          color: "#fff",
                        }}
                        className={disabled ? classes.disabled : ""}
                        disabled={disabled}
                        onClick={initTournament}
                      >
                        Iniciar
                      </Button>
                    </ModalCreate>
                  </div>
                ) : (
                  <b className={classes.label} style={{ color: "RED" }}>
                    CANCELADO
                  </b>
                )
              ) : (
                ""
              )}
              <label className={classes.label}>
                Total de jogadores: {tournament.total_players}
              </label>
              <label className={classes.label}>
                Valor do ticket: {tournament.ticket_value}
              </label>
              <label className={classes.label}>
                Valor máximo do primeiro prêmio: {tournament.max_award_value}
              </label>
              <label className={classes.label}>
                Valor máximo do segundo prêmio:{" "}
                {tournament.second_max_award_value
                  ? tournament.second_max_award_value
                  : "-"}
              </label>
              <label className={classes.label}>
                Valor do primeiro prêmio:{" "}
                {tournament.award_value ? tournament.award_value : "-"}
              </label>
              <label className={classes.label}>
                Valor do segundo prêmio:{" "}
                {tournament.second_award_value
                  ? tournament.second_award_value
                  : "-"}
              </label>
              <label className={classes.label}>
                Início: {tournament.start_date} {tournament.start_time}
              </label>
              <label className={classes.label}>
                Console: {tournament.gameMode?.gameWithPlatform?.platform?.name}
              </label>
              <label className={classes.label}>
                Jogo: {tournament.gameMode?.gameWithPlatform?.game?.name}
              </label>
              <label className={classes.label}>
                Modo de Jogo: {tournament.gameMode?.name}
              </label>
              <div className={classes.divSelect}>
                <select
                  required
                  name={"total_players"}
                  style={{ paddingRight: 50 }}
                  className={classes.input}
                  onChange={({ target }) => setBracket(target.value)}
                >
                  {tournament.total_players > 8 ? (
                    <option value="octavefinal">Oitavas de final</option>
                  ) : (
                    ""
                  )}
                  {tournament.total_players > 4 ? (
                    <option value="quarterfinal">Quartas de final</option>
                  ) : (
                    ""
                  )}
                  <option value="semifinal">Semifinal</option>
                  <option value="final">Final</option>
                </select>
              </div>
              {tournament?.matches
                ?.filter((elem) => elem.bracket === bracket)
                .map((match) => (
                  <div
                    className={classes.match}
                    style={{ backgroundColor: getStatus(match.status).color }}
                    key={match.id}
                  >
                    <div className={classes.matchHeader}>
                      <b className={classes.label}>
                        {getStatus(match.status).status}
                      </b>
                    </div>
                    <div className={classes.matchParticipant}>
                      <div className={classes.matchParticipantContainer}>
                        <img
                          src={
                            match.participants[0]
                              ? match.participants[0].user.avatar
                              : user
                          }
                          alt="player1"
                          className={classes.user}
                        ></img>
                        <b className={classes.label}>
                          {match.participants[0]
                            ? match.participants[0].user.nick_name
                            : "Indefinido"}
                          {match.participants[0] ? (
                            <>
                              {!match.participants[0].user.active ? (
                                <>
                                  <br />
                                  <span style={{ color: "red" }}>
                                    BLOQUEADO
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                              <br />
                              <ModalText
                                style={{ width: "fit-content" }}
                                title="Infos"
                                className={classes.infos}
                                text={
                                  <div
                                    style={{
                                      width: "fit-content",
                                      padding: 10,
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label className={classes.label}>
                                      Nome:{" "}
                                      {match.participants[0].user.full_name}
                                    </label>
                                    <label className={classes.label}>
                                      E-mail: {match.participants[0].user.email}
                                    </label>
                                    <label className={classes.label}>
                                      Telefone:{" "}
                                      {match.participants[0].user.phone}
                                    </label>
                                    <label className={classes.label}>
                                      Nicknames:
                                    </label>
                                    {match.participants[0].user.platforms.map(
                                      (platform) => (
                                        <label
                                          key={platform.id}
                                          className={classes.label}
                                        >
                                          {platform.platform_identifier}
                                        </label>
                                      )
                                    )}
                                    <label className={classes.label}>
                                      CrossPlayIds:
                                    </label>
                                    {match.participants[0].user.crossPlayIds.map(
                                      (crossPlayId) => (
                                        <div key={crossPlayId.id}>
                                          <label className={classes.label}>
                                            Jogo:{" "}
                                            {crossPlayId.game_with_platform_id ===
                                            tournament.gameMode
                                              ?.gameWithPlatform?.id
                                              ? tournament.gameMode
                                                  ?.gameWithPlatform?.game?.name
                                              : ""}
                                          </label>

                                          <label className={classes.label}>
                                            Id no jogo:{" "}
                                            {crossPlayId?.cross_play_id}
                                          </label>
                                        </div>
                                      )
                                    )}
                                  </div>
                                }
                              />
                              <br />
                              <a
                                target="_blank"
                                href={`/userFinancials/${match.participants[0].user.id}`}
                                rel="noreferrer"
                              >
                                Extrato
                              </a>
                              {match.participants[0].checkin &&
                              !tournament.started ? (
                                <>
                                  <br />
                                  <span style={{ color: "#17882c" }}>
                                    Check-in
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                              {match.winner ? (
                                match.participants[0].user_id ===
                                match.winner ? (
                                  <>
                                    <br />
                                    <span style={{ color: "#17882c" }}>
                                      Ganhador
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <br />
                                    <span style={{ color: "Red" }}>
                                      Perdedor
                                    </span>
                                  </>
                                )
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </b>
                      </div>
                      {match.participants[0] ? (
                        match.participants[0].evidence &&
                        match.participants[0].evidence !== "loser" ? (
                          <div style={{ textAlign: "center", width: 120 }}>
                            <ModalImage
                              className={classes.icon}
                              img={match.participants[0].evidence_url}
                            />
                            <b
                              className={classes.label}
                              style={{ color: "#17882c" }}
                            >
                              Vitória
                            </b>
                          </div>
                        ) : match.participants[0].evidence === "loser" ? (
                          <div style={{ textAlign: "center", width: 120 }}>
                            <b
                              className={classes.label}
                              style={{ color: "red" }}
                            >
                              Derrota
                            </b>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      <ModalIcon
                        style={{
                          backgroundColor: "#8b0000",
                          color: "#fff",
                          padding: 5,
                          width: 34,
                          margin: "0 4px",
                        }}
                        icon={faTrash}
                        iconWidth={20}
                        disabled={!match.participants[0]}
                        className={
                          !match.participants[0] ? classes.disabled : ""
                        }
                      >
                        <CardHeader
                          subheader="Tem certeza que deseja remover o participante do torneio? O valor será estornado."
                          title="Remover participante."
                        />
                        {error ? (
                          <>
                            <span style={{ color: "red" }}>{error}</span>
                            <br />
                          </>
                        ) : (
                          ""
                        )}

                        <Button
                          variant="contained"
                          style={{
                            margin: 10,
                            backgroundColor: "#8b0000",
                            color: "#fff",
                          }}
                          className={disabled ? classes.disabled : ""}
                          disabled={disabled}
                          onClick={() =>
                            handleRemoveParticipant(
                              match.participants[0].user_id
                            )
                          }
                          type="submit"
                        >
                          Remover
                        </Button>
                      </ModalIcon>
                    </div>
                    <div className={classes.matchParticipant}>
                      <div className={classes.matchParticipantContainer}>
                        <img
                          src={
                            match.participants[1]
                              ? match.participants[1].user.avatar
                              : user
                          }
                          alt="player1"
                          className={classes.user}
                        ></img>
                        <b className={classes.label}>
                          {match.participants[1]
                            ? match.participants[1].user.nick_name
                            : "Indefinido"}
                          {match.participants[1] ? (
                            <>
                              {!match.participants[1].user.active ? (
                                <>
                                  <br />
                                  <span style={{ color: "red" }}>
                                    BLOQUEADO
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                              <br />
                              <ModalText
                                style={{ width: "fit-content" }}
                                title="Infos"
                                className={classes.infos}
                                text={
                                  <div
                                    style={{
                                      width: "fit-content",
                                      padding: 10,
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <label className={classes.label}>
                                      Nome:{" "}
                                      {match.participants[1].user.full_name}
                                    </label>
                                    <label className={classes.label}>
                                      E-mail: {match.participants[1].user.email}
                                    </label>
                                    <label className={classes.label}>
                                      Telefone:{" "}
                                      {match.participants[1].user.phone}
                                    </label>
                                    <label className={classes.label}>
                                      Nicknames:
                                    </label>
                                    {match.participants[1].user.platforms.map(
                                      (platform) => (
                                        <label
                                          key={platform.id}
                                          className={classes.label}
                                        >
                                          {platform.platform_identifier}
                                        </label>
                                      )
                                    )}
                                    <label className={classes.label}>
                                      CrossPlayIds:
                                    </label>
                                    {match.participants[1].user.crossPlayIds.map(
                                      (crossPlayId) => (
                                        <div key={crossPlayId.id}>
                                          <label className={classes.label}>
                                            Jogo:{" "}
                                            {crossPlayId.game_with_platform_id ===
                                            tournament.gameMode
                                              ?.gameWithPlatform?.id
                                              ? tournament.gameMode
                                                  ?.gameWithPlatform?.game?.name
                                              : ""}
                                          </label>

                                          <label className={classes.label}>
                                            Id no jogo:{" "}
                                            {crossPlayId?.cross_play_id}
                                          </label>
                                        </div>
                                      )
                                    )}
                                  </div>
                                }
                              />
                              <br />
                              <a
                                target="_blank"
                                href={`/userFinancials/${match.participants[1].user.id}`}
                                rel="noreferrer"
                              >
                                Extrato
                              </a>
                              {match.participants[1].checkin &&
                              !tournament.started ? (
                                <>
                                  <br />
                                  <span style={{ color: "#17882c" }}>
                                    Check-in
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                              {match.winner ? (
                                match.participants[1].user_id ===
                                match.winner ? (
                                  <>
                                    <br />
                                    <span style={{ color: "#17882c" }}>
                                      Ganhador
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <br />
                                    <span style={{ color: "Red" }}>
                                      Perdedor
                                    </span>
                                  </>
                                )
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </b>
                      </div>
                      {match.participants[1] ? (
                        match.participants[1].evidence &&
                        match.participants[1].evidence !== "loser" ? (
                          <div style={{ textAlign: "center", width: 120 }}>
                            <ModalImage
                              className={classes.icon}
                              img={match.participants[1].evidence_url}
                            />
                            <b
                              className={classes.label}
                              style={{ color: "#17882c" }}
                            >
                              Vitória
                            </b>
                          </div>
                        ) : match.participants[1].evidence === "loser" ? (
                          <div style={{ textAlign: "center", width: 120 }}>
                            <b
                              className={classes.label}
                              style={{ color: "red" }}
                            >
                              Derrota
                            </b>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      <ModalIcon
                        style={{
                          backgroundColor: "#8b0000",
                          color: "#fff",
                          padding: 5,
                          width: 34,
                          margin: "0 4px",
                        }}
                        icon={faTrash}
                        iconWidth={20}
                        disabled={!match.participants[1]}
                        className={
                          !match.participants[1] ? classes.disabled : ""
                        }
                      >
                        <CardHeader
                          subheader="Tem certeza que deseja remover o participante do torneio? O valor será estornado."
                          title="Remover participante."
                        />
                        {error ? (
                          <>
                            <span style={{ color: "red" }}>{error}</span>
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        <Button
                          variant="contained"
                          style={{
                            margin: 10,
                            backgroundColor: "#8b0000",
                            color: "#fff",
                          }}
                          className={disabled ? classes.disabled : ""}
                          disabled={disabled}
                          onClick={() =>
                            handleRemoveParticipant(
                              match.participants[1].user_id
                            )
                          }
                          type="submit"
                        >
                          Remover
                        </Button>
                      </ModalIcon>
                    </div>
                    <div className={classes.matchFooter}>
                      {match.status === "w.o" ? (
                        <ModalReload
                          disabled={!getStatus(match.status).canConfirm}
                          style={{
                            backgroundColor: "#2543ca",
                            color: "#fff",
                            padding: 5,
                            width: 34,
                            marginRight: 10,
                          }}
                        >
                          <CardHeader
                            subheader="Tem certeza que deseja reiniciar cronômetro?"
                            title="Reiniciar cronômetro"
                          />
                          {match.participants[0] && match.participants[1] ? (
                            <Button
                              variant="contained"
                              style={{
                                margin: 10,
                                backgroundColor: "#2543ca",
                                color: "#fff",
                              }}
                              onClick={() => handleReload(match.id)}
                              className={disabled ? classes.disabled : ""}
                              disabled={disabled}
                              type="submit"
                            >
                              Reiniciar
                            </Button>
                          ) : (
                            ""
                          )}
                        </ModalReload>
                      ) : (
                        ""
                      )}
                      <ModalConfirm
                        disabled={!getStatus(match.status).canConfirm}
                        style={{
                          backgroundColor: "#17882c",
                          color: "#fff",
                          padding: 5,
                          width: 34,
                          opacity: getStatus(match.status).canConfirm ? 1 : 0.5,
                        }}
                      >
                        <CardHeader
                          subheader="Selecione o jogador vencedor."
                          title="Confirmar ganhador"
                        />
                        {match.participants[0] && match.participants[1] ? (
                          <Form
                            style={{ position: "relative" }}
                            ref={formRef}
                            onSubmit={handleSubmit}
                          >
                            <Select
                              defaultValue=""
                              style={{ backgroundColor: "#e8e8e8" }}
                              className={classes.input}
                              required
                              name="winner"
                            >
                              <option value="" disabled>
                                Selecione o ganhador
                              </option>
                              <option value={match.participants[0].user.id}>
                                {match.participants[0].user.nick_name}
                              </option>
                              <option value={match.participants[1].user.id}>
                                {match.participants[1].user.nick_name}
                              </option>
                            </Select>
                            <Input
                              required
                              name="id"
                              type="hidden"
                              value={match.id}
                            />
                            <Button
                              variant="contained"
                              style={{
                                margin: 10,
                                backgroundColor: "#17882c",
                                color: "#fff",
                              }}
                              className={disabled ? classes.disabled : ""}
                              disabled={disabled}
                              type="submit"
                            >
                              Confirmar
                            </Button>
                          </Form>
                        ) : (
                          ""
                        )}
                      </ModalConfirm>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Tournaments);
