import React from 'react';
import Users from '../components/views/Users';
import withRoot from '../components/withRoot';

function Index() {

  return (
    <React.Fragment>
      <Users />
    </React.Fragment>
  );
}

export default withRoot(Index);
