import React from "react";
import SelectGames from "../components/views/SelectGames";
import withRoot from "../components/withRoot";

function Index() {
  return (
    <React.Fragment>
      <SelectGames />
    </React.Fragment>
  );
}

export default withRoot(Index);
