import React from 'react';
import Games from '../components/views/Games';
import withRoot from '../components/withRoot';

function Index() {

  return (
    <React.Fragment>
      <Games />
    </React.Fragment>
  );
}

export default withRoot(Index);
