import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import GilroyRegular from "../../assets/Gilroy-Regular.ttf";
import background from "../../assets/backgroundControls.jpg";
import logo from "../../assets/whiteLogo.png";
import api from "../../services/api";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import moment from "moment";

import { Link } from "react-router-dom";
import ModalText from "../components/ModalText";
import { ArrowBack } from "@material-ui/icons";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faThumbsUp,
  faThumbsDown,
} from "@fortawesome/free-solid-svg-icons";

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100vw",
    backgroundRepeat: "repeat",
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  div: {
    maxWidth: "100vw",
    backgroundColor: "#e8e8e8",
    borderRadius: 30,
    overflowX: "auto",
  },
  content: {
    zIndex: 5,
    display: "flex",
    paddingTop: 30,
    paddingBottom: 30,
    flexDirection: "column",
  },
  input: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    borderRadius: 25,
    border: "none",
    color: "#383838",
    backgroundColor: "#fff",
    padding: 16,
    width: "100%",
    marginTop: "1%",
    marginBottom: "4%",
    position: "relative",
    WebkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
  },
  btn: {
    color: "#fff",
    borderWidth: 0,
    borderRadius: 40,
    fontSize: 16,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    backgroundImage: "linear-gradient(180deg,#e6007e 0%,#e94834 100%)",
    padding: "0.8em 1em",
    lineHeight: "1.7em",
    width: "100%",
    fontWeight: 600,
    margin: "5% 0",
  },
  "@font-face": {
    fontFamily: "Gilroy-regular",
    src: `url(${GilroyRegular})`,
  },
  logo: {
    width: "30%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  brand: {
    width: "15%",
    padding: "3%",
  },
  titleBrand: {
    padding: "1% 0",
    display: "flex",
    alignItems: "center",
    marginLeft: "-3%",
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    padding: "2% 1%",
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
    width: "100%",
  },
  label: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 16,
    paddingLeft: "2%",
    whiteSpace: "nowrap",
  },
  forgotDiv: {
    width: "100%",
    textAlign: "right",
  },
  forgot: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    textDecoration: "none",
  },
  errorDiv: {
    width: "100%",
    textAlign: "right",
    margin: 0,
    position: "absolute",
  },
  error: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#D84141",
    fontSize: 14,
    textDecoration: "none",
    margin: 0,
    fontWeight: 600,
  },
  singIn: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
  },
  singInLink: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: 600,
  },
  iconPassword: {
    color: "#383838",
    right: 21,
    top: 8,
    height: 44,
    fontSize: 20,
    position: "absolute",
  },
  quit: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#e6007e",
    width: "fit-content",
    textAlign: "left",
    textDecoration: "none",
    cursor: "pointer",
    paddingLeft: 16,
    paddingRight: 16,
  },
  createLink: {
    padding: "2% 0",
    textDecoration: "none",
    display: "flex",
    justifyContent: "flex-end",
    width: "50%",
  },
  icon: {
    fontSize: 30,
  },
  results: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  tableCell: {
    fontSize: 12,
  },
  divSelect: {
    position: "relative",
    width: "50%",
    "&:after": {
      content: "'\\f078'",
      font: "normal normal normal 17px/1 FontAwesome",
      color: "#383838",
      right: 21,
      top: 4,
      height: 34,
      padding: "12px 0px 0px 8px",
      position: "absolute",
      pointerEvents: "none",
    },
  },
});

function MatchFeedbacks(props) {
  const { classes } = props;

  const [feedbacks, setFeedbacks] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [gameId, setGameId] = React.useState("");
  const [platformId, setPlatformId] = React.useState("");
  const [platforms, setPlatforms] = React.useState([]);
  const [games, setGames] = React.useState([]);
  const [like, setLike] = React.useState("");

  useEffect(() => {
    async function setValues() {
      var response = await api.get(
        `/match/listFeedbacks?page=${page}&limit=${limit}&search=${search}&like=${like}&game_id=${gameId}&platform_id=${platformId}`
      );
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].time = moment(response.data[i].created_at)
          .utcOffset("-0300")
          .format("HH:mm");
        response.data[i].created_at = moment(response.data[i].created_at)
          .utcOffset("-0300")
          .format("DD/MM/YYYY");
      }
      setFeedbacks(response.data);
    }
    setValues();
  }, [page, limit, search, like, gameId, platformId]);

  useEffect(() => {
    async function setPlatformsGamesValues() {
      let platforms = await api.get(`/platforms`);
      setPlatforms(platforms.data);
      let games = await api.get(`/games`);
      setGames(games.data);
    }
    setPlatformsGamesValues();
  }, []);

  function likeHandleChange(e) {
    setLike(e.target.value);
  }

  function platformHandleChange(e) {
    setPlatformId(e.target.value);
  }

  function gameHandleChange(e) {
    setGameId(e.target.value);
  }

  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <ResponsiveAppBar></ResponsiveAppBar>
      <div className={classes.div}>
        <div className={classes.content}>
          <Link className={classes.quit} to="/panel">
            <ArrowBack fontSize="large" />
          </Link>
          <div>
            <Table size="small" style={{ whiteSpace: "nowrap" }}>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.title}>Feedbacks de partidas</div>{" "}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <TextField
                      fullWidth
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          setPage(0);
                          setSearch(e.target.value);
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <FontAwesomeIcon icon={faSearch} />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Buscar Feedback (ID, Nome, E-mail, CPF, Telefone, País ou Estado)"
                      variant="outlined"
                      style={{ backgroundColor: "#fff", minWidth: 200 }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colspan={2} className={classes.tableCell}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <label
                        htmlFor="like"
                        style={{ marginRight: 10 }}
                        className={classes.label}
                      >
                        Feedback:
                      </label>
                      <div className={classes.divSelect}>
                        <select
                          id="like"
                          defaultValue={like}
                          onChange={likeHandleChange}
                          className={classes.input}
                        >
                          <option value="">Todos</option>
                          <option value="true">Positivo</option>
                          <option value="false">Negativo</option>
                        </select>
                      </div>
                      <label
                        htmlFor="platform"
                        style={{ marginRight: 10 }}
                        className={classes.label}
                      >
                        Console:
                      </label>
                      <div className={classes.divSelect}>
                        <select
                          id="platform"
                          defaultValue={platformId}
                          onChange={platformHandleChange}
                          className={classes.input}
                        >
                          <option value="">Todos</option>
                          {platforms.map((platform) => {
                            return (
                              <option key={platform.id} value={platform.id}>
                                {platform.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <label
                        htmlFor="game"
                        style={{ marginRight: 10 }}
                        className={classes.label}
                      >
                        Jogo:
                      </label>
                      <div className={classes.divSelect}>
                        <select
                          id="game"
                          defaultValue={gameId}
                          onChange={gameHandleChange}
                          className={classes.input}
                        >
                          <option value="">Todos</option>
                          {games.map((game) => {
                            return (
                              <option key={game.id} value={game.id}>
                                {game.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table style={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    ID partida
                  </TableCell>
                  <TableCell className={classes.tableCell}>Usuário</TableCell>
                  <TableCell className={classes.tableCell}>Console</TableCell>
                  <TableCell className={classes.tableCell}>Jogo</TableCell>
                  <TableCell className={classes.tableCell}>
                    Modo de Jogo
                  </TableCell>
                  <TableCell className={classes.tableCell}>Data</TableCell>
                  <TableCell className={classes.tableCell}>Hora</TableCell>
                  <TableCell className={classes.tableCell}>Ganhou?</TableCell>
                  <TableCell className={classes.tableCell}>Feedback</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {feedbacks.map((feedback) => (
                  <TableRow
                    hover
                    key={feedback.id}
                    style={{ backgroundColor: "#fff" }}
                  >
                    <TableCell className={classes.tableCell}>
                      {feedback.match_id}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {feedback.user ? (
                        <>
                          {!feedback.user.active ? (
                            <>
                              <span style={{ color: "red" }}>BLOQUEADO</span>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          <b>Nick GG:</b> {feedback.user.nick_name} <br />
                          <b>ID Console:</b>{" "}
                          {feedback.user.platforms.find(
                            (plat) =>
                              plat.platform_id === feedback.match.platform_id
                          )
                            ? feedback.user.platforms.find(
                                (plat) =>
                                  plat.platform_id ===
                                  feedback.match.platform_id
                              ).platform_identifier
                            : feedback.user.crossPlayIds.find(
                                (cross) =>
                                  cross.game_with_platform_id ===
                                  feedback.match.room.gameMode
                                    .game_with_platform_id
                              )
                            ? feedback.user.crossPlayIds.find(
                                (cross) =>
                                  cross.game_with_platform_id ===
                                  feedback.match.room.gameMode
                                    .game_with_platform_id
                              ).cross_play_id
                            : "-"}{" "}
                          <br />
                          <b>Telefone:</b> {feedback.user.phone} <br />
                          <ModalText
                            style={{ width: "fit-content" }}
                            title="Infos"
                            className={classes.results}
                            text={
                              <div
                                style={{
                                  width: "fit-content",
                                  padding: 10,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className={classes.label}>
                                  Nome: {feedback.user.full_name}
                                </label>
                                <label className={classes.label}>
                                  E-mail: {feedback.user.email}
                                </label>
                                <label className={classes.label}>
                                  Nicknames:
                                </label>
                                {feedback.user.platforms.map((platform) => (
                                  <label
                                    key={platform.id}
                                    className={classes.label}
                                  >
                                    {platform.platform_identifier}
                                  </label>
                                ))}
                                {feedback.user.crossPlayIds.map((cross) => (
                                  <label
                                    key={cross.id}
                                    className={classes.label}
                                  >
                                    {cross.cross_play_id}
                                  </label>
                                ))}
                              </div>
                            }
                          />
                          <br />
                          <a
                            target="_blank"
                            href={`/userFinancials/${feedback.user.id}`}
                            rel="noreferrer"
                          >
                            Extrato
                          </a>
                        </>
                      ) : (
                        "EXCLUÍDO"
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {
                        feedback.match.room.gameMode.gameWithPlatform.platform
                          .name
                      }
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {feedback.match.room.gameMode.gameWithPlatform.game.name}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {feedback.match.room.gameMode.description}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {feedback.created_at}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      {feedback.time}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {" "}
                      {feedback.match.winner === feedback.user_id
                        ? "Sim"
                        : "Não"}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {" "}
                      <FontAwesomeIcon
                        size="3x"
                        color={feedback.like ? "green" : "red"}
                        icon={feedback.like ? faThumbsUp : faThumbsDown}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={-1}
              page={page}
              labelRowsPerPage="Linhas por página:"
              labelDisplayedRows={({ page }) => {
                return `Página: ${page + 1}`;
              }}
              onPageChange={(e, val) => setPage(val)}
              rowsPerPage={limit}
              onRowsPerPageChange={({ target }) => {
                setLimit(target.value);
                setPage(0);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(MatchFeedbacks);
