import React from 'react';
import CreateMatches from '../components/views/CreateMatches';
import withRoot from '../components/withRoot';

function Index() {

  return (
    <React.Fragment>
      <CreateMatches />
    </React.Fragment>
  );
}

export default withRoot(Index);
