import React from "react";
import { withStyles } from "@material-ui/core/styles";
import GilroyRegular from "../../assets/Gilroy-Regular.ttf";
import background from "../../assets/backgroundControls.jpg";
import logo from "../../assets/whiteLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faTrophy,
  faBolt,
  faGamepad,
  faGift,
  faFileInvoiceDollar,
  faMedal,
  faDollarSign,
  faMoneyCheckAlt,
  faTicketAlt,
  faAnchor,
  faIdCard,
  faThumbsUp,
  faChartSimple,
} from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
const styles = (theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100vw",
    backgroundRepeat: "repeat",
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  div: {
    width: "35%",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  content: {
    backgroundColor: "#e8e8e8",
    padding: 30,
    margin: "auto",
    marginBottom: 30,
    backgroundSize: "cover",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    borderRadius: 30,
    zIndex: 5,
    display: "flex",
    flexDirection: "column",
  },
  input: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    borderRadius: 25,
    border: "none",
    color: "#383838",
    backgroundColor: "#fff",
    padding: 16,
    width: "100%",
    marginTop: "2%",
    marginBottom: "2%",
  },
  btn: {
    color: "#fff",
    borderWidth: 0,
    borderRadius: 20,
    fontSize: 16,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    backgroundImage: "linear-gradient(180deg,#e6007e 0%,#e94834 100%)",
    padding: "0.8em 1em",
    width: "45%",
    fontWeight: 600,
    margin: "2% 0",
    textAlign: "center",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    lineHeight: 1,
  },
  "@font-face": {
    fontFamily: "Gilroy-regular",
    src: `url(${GilroyRegular})`,
  },
  logo: {
    width: "30%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },

  title: {
    padding: "1% 0",
    fontSize: 30,
    fontWeight: 600,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
    textAlign: "center",
  },
  balance: {
    fontSize: 40,
    fontWeight: 600,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
    textAlign: "center",
  },
  subtitle: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 16,
    padding: "2% 0",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  subtitle2: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 15,
    padding: "2% 0",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  quit: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  icon: {
    fontSize: 60,
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
});

function Panel(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <div className={classes.div}>
        <div className={classes.content}>
          <div className={classes.title}>Painel Administrativo</div>
          <div className={classes.row}>
            <Link className={classes.btn} to="/payments">
              <FontAwesomeIcon
                className={classes.icon}
                icon={faFileInvoiceDollar}
              />
              <div style={{ marginTop: "10px" }}>Depósitos de usuários</div>
            </Link>
            <Link
              className={classes.btn}
              to="/wins"
              style={{ backgroundColor: "#41D886", backgroundImage: "none" }}
            >
              <FontAwesomeIcon className={classes.icon} icon={faMedal} />
              <span style={{ marginTop: "10px" }}>Avaliar partidas</span>
            </Link>
          </div>
          <div className={classes.row}>
            <Link
              className={classes.btn}
              to="/selectGames/tournaments"
              style={{ backgroundColor: "#41D886", backgroundImage: "none" }}
            >
              <FontAwesomeIcon className={classes.icon} icon={faTrophy} />
              <div style={{ marginTop: "10px" }}>Torneios</div>
            </Link>
            <Link className={classes.btn} to="/matches">
              <FontAwesomeIcon className={classes.icon} icon={faBolt} />
              <span style={{ marginTop: "10px" }}>
                Criação de Partidas rápidas
              </span>
            </Link>
          </div>
          <div className={classes.row}>
            <Link className={classes.btn} to="/deposits">
              <FontAwesomeIcon
                className={classes.icon}
                icon={faMoneyCheckAlt}
              />
              <span style={{ marginTop: "10px" }}>Solicitações de saque</span>
            </Link>
            <Link
              className={classes.btn + " " + classes.disabled}
              to="/"
              style={{ backgroundColor: "#41D886", backgroundImage: "none" }}
            >
              <FontAwesomeIcon className={classes.icon} icon={faTicketAlt} />
              <div style={{ marginTop: "10px" }}>Vouchers</div>
            </Link>
          </div>
          <div className={classes.row}>
            <Link
              className={classes.btn}
              to="/financial"
              style={{ backgroundColor: "#41D886", backgroundImage: "none" }}
            >
              <FontAwesomeIcon className={classes.icon} icon={faDollarSign} />
              <div style={{ marginTop: "10px" }}>Financeiro</div>
            </Link>
            <Link className={classes.btn + " " + classes.disabled} to="/awards">
              <FontAwesomeIcon className={classes.icon} icon={faGift} />
              <span style={{ marginTop: "10px" }}>Prêmio mensal</span>
            </Link>
          </div>
          <div className={classes.row}>
            <Link
              className={classes.btn + " " + classes.disabled}
              to="/consolegame"
            >
              <FontAwesomeIcon className={classes.icon} icon={faGamepad} />
              <div style={{ marginTop: "10px" }}>Jogos e Consoles</div>
            </Link>
            <Link
              className={classes.btn}
              to="/users"
              style={{ backgroundColor: "#41D886", backgroundImage: "none" }}
            >
              <FontAwesomeIcon className={classes.icon} icon={faUsers} />
              <span style={{ marginTop: "10px" }}>Usuários</span>
            </Link>
          </div>
          <div className={classes.row}>
            <Link
              className={classes.btn}
              to="/referrals"
              style={{ backgroundColor: "#41D886", backgroundImage: "none" }}
            >
              <FontAwesomeIcon className={classes.icon} icon={faAnchor} />
              <span style={{ marginTop: "10px" }}>Indicações</span>
            </Link>
            <Link className={classes.btn} to="/validationDocuments">
              <FontAwesomeIcon className={classes.icon} icon={faIdCard} />
              <span style={{ marginTop: "10px" }}>Validação de documentos</span>
            </Link>
          </div>
          <div className={classes.row}>
            <Link className={classes.btn} to="/matchFeedbacks">
              <FontAwesomeIcon className={classes.icon} icon={faThumbsUp} />
              <span style={{ marginTop: "10px" }}>Feedbacks de partidas</span>
            </Link>
            <Link
              className={classes.btn}
              to="/dashboardAuth"
              style={{ backgroundColor: "#41D886", backgroundImage: "none" }}
            >
              <FontAwesomeIcon className={classes.icon} icon={faChartSimple} />
              <span style={{ marginTop: "10px" }}>Dashboard</span>
            </Link>
          </div>
          <p
            className={classes.quit}
            onClick={() => {
              localStorage.removeItem("adminData");
              localStorage.removeItem("token");
              window.location.assign("/");
            }}
          >
            Sair
          </p>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Panel);
