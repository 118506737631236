import React from 'react';
import PendDeposits from '../components/views/PendDeposits';
import withRoot from '../components/withRoot';

function Index() {

  return (
    <React.Fragment>
      <PendDeposits />
    </React.Fragment>
  );
}

export default withRoot(Index);
