import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import GilroyRegular from "../../assets/Gilroy-Regular.ttf";
import background from "../../assets/backgroundControls.jpg";
import logo from "../../assets/whiteLogo.png";
import api from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardHeader,
  Button,
  TablePagination,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import ModalIcon from "../components/ModalIcon";
import { faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import { ArrowBack } from "@material-ui/icons";

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100vw",
    backgroundRepeat: "repeat",
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  div: {
    maxWidth: "100vw",
    backgroundColor: "#e8e8e8",
    borderRadius: 30,
    overflowX: "auto",
  },
  content: {
    zIndex: 5,
    display: "flex",
    paddingTop: 30,
    paddingBottom: 30,
    flexDirection: "column",
  },
  input: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    borderRadius: 25,
    border: "none",
    color: "#383838",
    backgroundColor: "#e8e8e8",
    padding: 16,
    width: "100%",
    marginTop: "1%",
    marginBottom: "4%",
    position: "relative",
  },
  btn: {
    color: "#fff",
    borderWidth: 0,
    borderRadius: 40,
    fontSize: 16,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    backgroundImage: "linear-gradient(180deg,#e6007e 0%,#e94834 100%)",
    padding: "0.8em 1em",
    lineHeight: "1.7em",
    width: "100%",
    fontWeight: 600,
    margin: "5% 0",
  },
  "@font-face": {
    fontFamily: "Gilroy-regular",
    src: `url(${GilroyRegular})`,
  },
  logo: {
    width: "30%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  brand: {
    width: "15%",
    padding: "3%",
  },
  titleBrand: {
    padding: "1% 0",
    display: "flex",
    alignItems: "center",
    marginLeft: "-3%",
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    padding: "2% 1%",
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
    width: "100%",
  },
  label: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 16,
    paddingLeft: "2%",
  },
  forgotDiv: {
    width: "100%",
    textAlign: "right",
  },
  forgot: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    textDecoration: "none",
  },
  errorDiv: {
    width: "100%",
    textAlign: "right",
    margin: 0,
    position: "absolute",
  },
  error: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#D84141",
    fontSize: 14,
    textDecoration: "none",
    margin: 0,
    fontWeight: 600,
  },
  singIn: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
  },
  singInLink: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: 600,
  },
  iconPassword: {
    color: "#383838",
    right: 21,
    top: 8,
    height: 44,
    fontSize: 20,
    position: "absolute",
  },
  quit: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#e6007e",
    width: "fit-content",
    textAlign: "left",
    textDecoration: "none",
    cursor: "pointer",
    paddingLeft: 16,
    paddingRight: 16,
  },
  createLink: {
    padding: "2% 0",
    textDecoration: "none",
    display: "flex",
    justifyContent: "flex-end",
    width: "50%",
  },
  icon: {
    fontSize: 30,
  },
  results: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
  tableCell: {
    fontSize: 12,
  },
});

function Referrals(props) {
  const { classes } = props;
  const [referrals, setReferrals] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  useEffect(() => {
    async function setValues() {
      var response = await api.get(
        `/referral/all?page=${page}&limit=${limit}&search=${search}`
      );

      setReferrals(response.data);
      setDisabled(false);
    }
    setValues();
  }, [page, limit, search]);

  async function reload() {
    var response = await api.get(
      `/referral/all?page=${page}&limit=${limit}&search=${search}`
    );

    setReferrals(response.data);
    setDisabled(false);
  }

  async function applyBonus(id) {
    setDisabled(true);
    try {
      await api.post(`/referral/applyBonus/` + id, {});
      reload();
    } catch (e) {
      setDisabled(false);
    }
  }

  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <ResponsiveAppBar></ResponsiveAppBar>
      <div className={classes.div}>
        <div className={classes.content}>
          <Link className={classes.quit} to="/panel">
            <ArrowBack fontSize="large" />
          </Link>
          <div>
            <Table size="small" style={{ whiteSpace: "nowrap", fontSize: 10 }}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.title}>Indicações</div>{" "}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <TextField
                      fullWidth
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          setPage(0);
                          setSearch(e.target.value);
                          reload();
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <FontAwesomeIcon icon={faSearch} />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Buscar indicação (E-mail)"
                      variant="outlined"
                      style={{ backgroundColor: "#fff", minWidth: 200 }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Table style={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    Bônus gerado
                  </TableCell>
                  <TableCell className={classes.tableCell}>ID</TableCell>
                  <TableCell className={classes.tableCell}>Indicado</TableCell>
                  <TableCell className={classes.tableCell}>Indicador</TableCell>
                  <TableCell className={classes.tableCell}>
                    Indicado já jogou?
                  </TableCell>
                  <TableCell className={classes.tableCell}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {referrals.map((referral) => (
                  <TableRow
                    hover
                    key={referral.id}
                    style={{ backgroundColor: "#fff" }}
                  >
                    <TableCell className={classes.tableCell}>
                      {!referral.bonus_generated ? (
                        <div
                          style={{
                            backgroundColor: "red",
                            color: "#fff",
                            borderRadius: 10,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          NÃO
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "green",
                            color: "#fff",
                            borderRadius: 10,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          SIM
                        </div>
                      )}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {referral.id}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {referral.referred.full_name} ({referral.referred.email})
                      {!referral.referred.active ? (
                        <>
                          <br />
                          <span style={{ color: "red" }}>BLOQUEADO</span>
                        </>
                      ) : (
                        ""
                      )}
                      <br />
                      <a
                        target="_blank"
                        href={`/userFinancials/${referral.referred.id}`}
                        rel="noreferrer"
                      >
                        Extrato
                      </a>
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {referral.referral.full_name} ({referral.referral.email})
                      {!referral.referral.active ? (
                        <>
                          <br />
                          <span style={{ color: "red" }}>BLOQUEADO</span>
                        </>
                      ) : (
                        ""
                      )}
                      <br />
                      <a
                        target="_blank"
                        href={`/userFinancials/${referral.referral.id}`}
                        rel="noreferrer"
                      >
                        Extrato
                      </a>
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {!referral.referred_user_already_played ? (
                        <div
                          style={{
                            backgroundColor: "red",
                            color: "#fff",
                            borderRadius: 10,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          NÃO
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "green",
                            color: "#fff",
                            borderRadius: 10,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          SIM
                        </div>
                      )}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {!referral.bonus_generated ? (
                        <ModalIcon
                          style={{
                            backgroundColor: "#2543ca",
                            color: "#fff",
                            padding: 5,
                            width: 34,
                            margin: "0 4px",
                          }}
                          icon={faMoneyBillTransfer}
                          iconWidth={20}
                        >
                          <CardHeader
                            subheader="Tem certeza que deseja aplicar o bônus de indicação?"
                            title="Aplicar bônus."
                          />

                          <Button
                            variant="contained"
                            style={{
                              margin: 10,
                              backgroundColor: "#2543ca",
                              color: "#fff",
                            }}
                            className={disabled ? classes.disabled : ""}
                            disabled={disabled}
                            onClick={() => applyBonus(referral.id)}
                            type="submit"
                          >
                            Aplicar bônus
                          </Button>
                        </ModalIcon>
                      ) : (
                        <div style={{ marginLeft: 10, fontWeight: "bold" }}>
                          N/A
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={-1}
              page={page}
              labelRowsPerPage="Linhas por página:"
              labelDisplayedRows={({ page }) => {
                return `Página: ${page + 1}`;
              }}
              onPageChange={(e, val) => setPage(val)}
              rowsPerPage={limit}
              onRowsPerPageChange={({ target }) => {
                setLimit(target.value);
                setPage(0);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Referrals);
