import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import GilroyRegular from "../../assets/Gilroy-Regular.ttf";
import background from "../../assets/backgroundControls.jpg";
import logo from "../../assets/whiteLogo.png";
import api from "../../services/api";
import { Divider, Button, CardHeader } from "@material-ui/core";
import { Link } from "react-router-dom";
import ModalDelete from "../components/ModalDelete";
import ModalImage from "../components/ModalImage";
import { ArrowBack } from "@material-ui/icons";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
const styles = (theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100vw",
    backgroundRepeat: "repeat",
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  div: {
    width: "35%",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  content: {
    backgroundColor: "#e8e8e8",
    padding: 30,
    margin: "auto",
    backgroundSize: "cover",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    borderRadius: 30,
    zIndex: 5,
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  input: {
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif",
    fontSize: 14,
    borderRadius: 25,
    border: "none",
    color: "#383838",
    backgroundColor: "#fff",
    padding: 16,
    width: "100%",
    marginTop: "1%",
    marginBottom: "4%",
    position: "relative",
  },
  btn: {
    color: "#fff",
    borderWidth: 0,
    borderRadius: 40,
    fontSize: 16,
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    backgroundImage: "linear-gradient(180deg,#e6007e 0%,#e94834 100%)",
    padding: "0.8em 1em",
    lineHeight: "1.7em",
    width: "100%",
    fontWeight: 600,
    margin: "5% 0",
  },
  "@font-face": {
    fontFamily: "Gilroy-regular",
    src: `url(${GilroyRegular})`,
  },
  logo: {
    width: "30%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  brand: {
    width: "15%",
    padding: "3%",
  },
  titleBrand: {
    padding: "1% 0",
    display: "flex",
    alignItems: "center",
    marginLeft: "-3%",
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    padding: "2% 0",
    textAlign: "center",
    fontFamily: "'Gilroy',Helvetica,Arial,Lucida,sans-serif!important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "300%",
    backgroundImage:
      "linear-gradient(180deg,rgba(230,0,126,100) 0%,rgba(233,72,52,100) 100%)",
  },
  label: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 16,
    paddingLeft: "2%",
  },
  forgotDiv: {
    width: "100%",
    textAlign: "right",
  },
  forgot: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    textDecoration: "none",
  },
  errorDiv: {
    width: "100%",
    textAlign: "right",
    margin: 0,
    position: "absolute",
  },
  error: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#D84141",
    fontSize: 14,
    textDecoration: "none",
    margin: 0,
    fontWeight: 600,
  },
  singIn: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
  },
  singInLink: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#121212",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: 600,
  },
  iconPassword: {
    color: "#383838",
    right: 21,
    top: 8,
    height: 44,
    fontSize: 20,
    position: "absolute",
  },
  quit: {
    fontFamily: "'Gilroy-regular',Helvetica,Arial,Lucida,sans-serif!important",
    color: "#e6007e",
    width: "fit-content",
    textAlign: "left",
    textDecoration: "none",
    cursor: "pointer",
  },
  createLink: {
    padding: "2% 0",
    textDecoration: "none",
    display: "flex",
    justifyContent: "flex-end",
    width: "50%",
  },
  icon: {
    fontSize: 30,
  },
});

function Matches(props) {
  const { classes } = props;
  const [matches, setMatches] = React.useState([]);
  const [deleted, setDeleted] = React.useState(0);
  useEffect(() => {
    async function setValues() {
      let { data } = await api.get(`/games/allRooms`);
      setMatches(data);
    }
    setValues();
  }, [deleted]);

  async function deleteMatch(id) {
    try {
      await api.delete(`/games/room/` + id);
      setDeleted(deleted + 1);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <ResponsiveAppBar></ResponsiveAppBar>
      <div className={classes.div}>
        <div className={classes.content}>
          <Link className={classes.quit} to="/panel">
            <ArrowBack fontSize="large" />
          </Link>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className={classes.title}>Criação de Partidas rápidas</div>
            <Link className={classes.createLink} to="/matches/create">
              <Button
                style={{
                  margin: "2%",
                  backgroundColor: "#17882c",
                  color: "#fff",
                }}
                variant="contained"
              >
                Criar partida rápida{" "}
              </Button>
            </Link>
          </div>
          <Divider />
          {matches.map((match) => (
            <div
              style={{ display: "flex", flexDirection: "column" }}
              key={match.id}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "2% 0",
                }}
              >
                <b className={classes.label}>Partida rápida</b>
                <label className={classes.label}>
                  ID: <b>{match.id}</b>
                </label>
                <label className={classes.label}>
                  Valor do ticket:{" "}
                  <b>
                    {match.ticket_value.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </b>
                </label>
                <label className={classes.label}>
                  Valor do prêmio:{" "}
                  <b>
                    {match.award_value.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </b>
                </label>
                <label className={classes.label}>
                  Console:{" "}
                  <b>{match.gameMode.gameWithPlatform.platform.name}</b>
                </label>
                <label className={classes.label}>
                  Jogo: <b>{match.gameMode.gameWithPlatform.game.name}</b>
                </label>
                <label className={classes.label}>
                  Modo de Jogo: <b>{match.gameMode.name}</b>
                </label>
                <label className={classes.label}>
                  Capa:{" "}
                  <ModalImage
                    className={classes.icon}
                    img={match.cover_image_url}
                  />
                </label>
                <div style={{ display: "flex" }}>
                  <Link
                    style={{ margin: "2%", textDecoration: "none" }}
                    to={"/matches/edit/" + match.id}
                  >
                    <Button
                      style={{ backgroundColor: "#17882c", color: "#fff" }}
                      variant="contained"
                    >
                      Editar
                    </Button>
                  </Link>
                  <ModalDelete
                    style={{
                      margin: "2%",
                      backgroundColor: "#8B0000",
                      color: "#fff",
                    }}
                  >
                    <CardHeader
                      subheader={"Tem certeza que quer excluir esta partida?"}
                      title="Excluir partida"
                    />

                    <Button
                      variant="contained"
                      style={{
                        margin: 10,
                        backgroundColor: "#8B0000",
                        color: "#fff",
                      }}
                      onClick={() => deleteMatch(match.id)}
                    >
                      Excluir
                    </Button>
                  </ModalDelete>
                </div>
              </div>

              <Divider />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Matches);
